import './HeadLineM.scss';
import * as React from 'react';

interface IHeadLineM {
    className?: string,
}

const HeadLineM: React.FC<IHeadLineM> = ({ children, className }) => {
    const classList = [
        'head-line-m',
        className ? className : ''
    ].join(' ');

    return (
        <h4 className={classList}>
            {children}
        </h4>
    );
};

export { HeadLineM }
