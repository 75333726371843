import { Store, Action } from 'redux';

// TODO: Porządnie zrozumiec i opisac typowanie middleware's
// TODO: spiąc z loggerem ?

const loggerMiddleware = (store: Store) => (next: any) => (action: Action) => {
    console.group(action.type);
    console.info('dispatching', action);

    const result = next(action);

    console.log('next state', store.getState());
    console.groupEnd();

    return result;
};

export { loggerMiddleware };
