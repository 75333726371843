import './DesktopMenu.scss';
import * as React from 'react';
import { IconFlagUk } from '../../../../../assets/icons/IconFlagUk';
import { MenuItemsFinder } from '../../../../class/MenuItemsFinder';
import { SpecialActionsFinder } from '../../../../class/SpecialActionsFinder';
import { NavItem } from '../NavItem/NavItem';

interface IDesktopMenu {
    menuItemsFinder: MenuItemsFinder,
    specialActionsFinder: SpecialActionsFinder
}

const DesktopMenu: React.FC<IDesktopMenu> = ({ menuItemsFinder, specialActionsFinder }) => {
    const mainMenuItems = menuItemsFinder.getMainMenuItems();
    const specialActions = specialActionsFinder.getSpecialActionsMenuItems();

    return (
        <nav className="desktop-menu">
            <ul className="desktop-menu__list">
                {mainMenuItems.map((menuItem) => (
                    <li
                        className="desktop-menu__list-item"
                        key={menuItem.label.toLowerCase()}
                    >
                        <NavItem url={menuItem.uri}>
                            {menuItem.label}
                        </NavItem>
                    </li>
                ))}
                {specialActions.map((actionItem) => (
                    <li
                        className="desktop-menu__list-item"
                        key={actionItem.label.toLowerCase()}
                    >
                        <a
                            className="nav-item special-action-ua"
                            href={actionItem.uri}
                        >
                            <IconFlagUk />
                            {actionItem.label}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export { DesktopMenu, IDesktopMenu };

