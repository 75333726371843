import * as React from 'react';
import { SvgIcon } from './SvgIcon';
import btoa from 'btoa';

const IconProfile = () => {
    const gradientId = btoa('profile');

    return (
        <SvgIcon
            name="profile"
            svgXml={`
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="url(#${ gradientId })"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0004 19.7458H18.4604C15.6104 19.7458 12.9004 23.6559 12.9004 26.8759V27.5759H27.5104V26.8759C27.5504 23.6559 24.8304 19.7458 22.0004 19.7458Z" fill="#FFE000" stroke="white" stroke-width="2" stroke-miterlimit="2.61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5809 15.8857C25.5809 16.9398 25.2683 17.9703 24.6826 18.8469C24.097 19.7234 23.2645 20.4065 22.2906 20.8099C21.3167 21.2134 20.245 21.3189 19.2111 21.1133C18.1771 20.9076 17.2274 20.4 16.482 19.6545C15.7366 18.9091 15.229 17.9594 15.0233 16.9255C14.8177 15.8916 14.9232 14.8199 15.3266 13.846C15.73 12.872 16.4132 12.0396 17.2897 11.4539C18.1662 10.8683 19.1967 10.5557 20.2509 10.5557C21.6645 10.5557 23.0202 11.1172 24.0198 12.1168C25.0193 13.1164 25.5809 14.4721 25.5809 15.8857Z" fill="#FFE000" stroke="white" stroke-width="2" stroke-miterlimit="2.61"/>
                <defs>
                <radialGradient id="${ gradientId }" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20 20) rotate(90) scale(20)">
                <stop offset="0.635417" stop-color="#FFE000"/>
                <stop offset="1" stop-color="#FFE000" stop-opacity="0"/>
                </radialGradient>
                </defs>
                </svg>
            `}
        />
    );
};

export { IconProfile }
