/**
 * W tym pliku prowadzimy "rejestr" akcji jakie możemy wykonać, poszczególne akcje umieszczamy w plikach ./actions
 */

enum EReduxActionTypes {
    SHOW_SEARCH = 'SHOW_SEARCH',
    HIDE_SEARCH = 'HIDE_SEARCH',
    SHOW_NOTIFICATIONS = 'SHOW_NOTIFICATIONS',
    HIDE_NOTIFICATIONS = 'HIDE_NOTIFICATIONS',
    SHOW_MOBILE_MENU = 'SHOW_MOBILE_MENU',
    HIDE_MOBILE_MENU = 'HIDE_MOBILE_MENU',
    SET_LIVESEARCH_QUERY = 'SET_LIVESEARCH_QUERY',
    SET_LIVESEARCH_ISLOADING = 'SET_LIVESEARCH_ISLOADING'
}

interface IReduxBaseAction {
    type: EReduxActionTypes;
}

export {
    IReduxBaseAction, EReduxActionTypes
};
