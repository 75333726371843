/**
 * Stworzone na podstawie src/GoldenLine/LayoutBundle/Resources/public/js/livesearch.js
 */

import algoliasearch, { MultiResponse } from 'algoliasearch';

import { IWindow, TGlobalUserData } from '../../typings/IWindow';
import { getFirmQueries, TFirmData } from './getFirmQueries';
import { getUserQueries, TUserData } from './getUserQueries';
import { IAlgoliaQuery } from './AlgoliaQuery';
import { TJobData } from './getJobQueries';

type TSearchResults = {
    users: TUserData[];
    firms: TFirmData[];
    jobs: TJobData[]
}

type TAlgoliaResult = TUserData | TFirmData | TJobData;

declare const window: IWindow;

class HolmesSearch {
    private algolia: any;
    private readonly apiKey: string;
    private readonly tagFilters: string;
    private queries: IAlgoliaQuery[];

    constructor(apiKey: string, tagFilters: string) {
        this.apiKey = apiKey;
        this.tagFilters = tagFilters;

        this.initAlgolia();
        this.setQueries();
    }

    public getSearchResults(query: string): Promise<TSearchResults> {
        const { algolia, queries } = this;

        return new Promise<TSearchResults>((resolve, reject): void => {

            if (!this.isLoggedIn()) {
                reject('[LiveSearch] User is not logged in!');
            }

            this.updateQueries(query);

            algolia.search(queries, (error: Error, res: MultiResponse<TAlgoliaResult>) => {
                if (error) {
                    reject(error)
                }

                const searchResults: TSearchResults = {
                    users: [],
                    firms: [],
                    jobs: []
                };

                for (const result of res.results) {
                    const { hits, index } = result;

                    //TODO Spike - Czy bardziej opłaca się ifować (length > 0) aby nie doklejać pustych tablic?
                    switch (index) {
                        case 'users':
                            searchResults.users = [ ...searchResults.users, ...hits as TUserData[] ];
                            break;
                        case 'firms':
                            searchResults.firms = [ ...searchResults.firms, ...hits as TFirmData[] ];
                            break;
                        case 'jobs':
                            searchResults.jobs = [ ...searchResults.jobs, ...hits as TJobData[] ];
                            break;
                        default:
                        //TODO Log error
                    }
                }

                resolve(searchResults);
            });
        });
    }

    private updateQueries(newValue: string): void {
        for (const algoliaQuery of this.queries) {
            algoliaQuery.query = newValue;
        }
    }

    private initAlgolia(): void {
        this.algolia = algoliasearch('K1R2MWID72', this.apiKey, {
            hosts: {
                read: [
                    'holmes.goldenline.io'
                ],
                write: [
                    'livesearch.goldenline.io'
                ]
            }
        });

        this.algolia.setSecurityTags(this.tagFilters);
    }

    private setQueries(): void {
        const { CURRENT } = window;
        const currentUser = (CURRENT as TGlobalUserData).id;
        const userFilter = 'user_' + currentUser;

        const userQueries = getUserQueries('', userFilter);
        const firmQueries = getFirmQueries('', userFilter);
        //const jobQueries = getJobQueries('', userFilter); TODO: Zablokonie ofert pracy dopóki nie uzyskamy expires lub po nie nie wyfiltrujemy wyników

        this.queries = [
            userQueries[0], userQueries[1],
            firmQueries[0], firmQueries[1],
            //jobQueries[0], jobQueries[1] TODO jak todo wyżej
        ];
    }

    private isLoggedIn(): boolean {
        const { CURRENT } = window;

        return CURRENT && Object.keys(CURRENT).length > 0;
    }
}

export { HolmesSearch, TAlgoliaResult, TSearchResults };
