import * as React from 'react';
import {SvgIcon} from './SvgIcon';

const IconFlagUk = () => {
    return (
        <SvgIcon
            name="IconFlagUk"
            svgXml={`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_140_1732" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                        <circle cx="12" cy="12" r="12" fill="#C4C4C4"/>
                    </mask>
                    <g mask="url(#mask0_140_1732)">
                        <rect x="-4" width="32" height="12" fill="#005BBB"/>
                        <rect x="-4" y="12" width="32" height="12" fill="#FFD500"/>
                        <circle cx="12" cy="12" r="11.75" stroke="#DADADA" stroke-width="0.5"/>
                    </g>
                </svg>
            `}
        />
    );
};

export { IconFlagUk };
