import { TFunction } from 'i18next';
import { TUserData } from '../components/bl/Header/Header';

type TMenuItem = {
    label: string;
    locale?: string;
    onClick?: () => void;
    uri: string;
};

class MenuItemsFinder {
    public dynamicMenuItems: { [key: string]: TMenuItem };

    private loggedIn: boolean;

    constructor(
        private t: TFunction,
        private userData: TUserData | null,
        private hasHrs: boolean | null,
    ) {
        this.loggedIn = Boolean(this.userData);
        this.setDynamicMenuItems();
    }

    setDynamicMenuItems(): void {
        const { t } = this;

        this.dynamicMenuItems = {
            forEmployers: {
                label: t('header:nav:forEmployers'),
                uri: t('paths:url:businessOffer'),
            },
        };

        if (this.loggedIn) {
            const { userProfileUrlname } = this.userData as TUserData;

            this.dynamicMenuItems.myProfile = {
                label: t('header:nav:myProfile'),
                uri: `/${ userProfileUrlname }`,
            };

            this.dynamicMenuItems.logOut = {
                label: t('header:nav:logOut'),
                uri: t('paths:uri:logOut'),
            };
        }
    }

    getMainMenuItems(): TMenuItem[] {
        const { t, dynamicMenuItems } = this;

        const mainMenuItems: TMenuItem[] = [
            {
                label: t('header:nav:jobOffers'),
                uri: t('paths:uri:jobOffersSearch'),
            },
            {
                label: t('header:nav:employerProfiles'),
                uri: t('paths:uri:employerProfiles'),
            },
            {
                label: t('header:nav:careerCenter'),
                uri: t('paths:uri:careerCenter'),
            },
        ];

        if (this.loggedIn) {
            return Object.assign(mainMenuItems, dynamicMenuItems.myProfile);
        }

        return mainMenuItems;
    }

    getTopMenuItems(mobile?: boolean): TMenuItem[] {
        const { t, dynamicMenuItems } = this;

        let dependentMenuItems: TMenuItem[] = [
            {
                label: t('header:nav:buyJobAd'),
                uri: t('paths:url:shop:jobAdPublish'),
            }
        ];

        if (this.loggedIn && this.hasHrs) {
            dependentMenuItems = dependentMenuItems.concat([
                {
                    label: t('header:nav:forEmployers'),
                    uri: t('paths:url:businessOffer'),
                },
                {
                    label: t('header:nav:forEmployersHasHrs'),
                    uri: t('paths:url:hrsPeopleSearch'),
                }
            ]);
        }

        if (mobile) {
            return dependentMenuItems.concat([
                {
                    label: t('header:nav:contacts'),
                    uri: t('paths:uri:contacts'),
                },
                {
                    label: t('header:nav:settings'),
                    uri: t('paths:uri:settings'),
                },
            ]);
        }

        return dependentMenuItems.concat([
            {
                label: t('header:nav:contacts'),
                uri: t('paths:uri:contacts'),
            },
            {
                label: t('header:nav:settings'),
                uri: t('paths:uri:settings'),
            },
            {
                ...dynamicMenuItems.logOut,
            },
        ]);
    }
}

export { MenuItemsFinder, TMenuItem };
