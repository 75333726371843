import './JobOffersTeaser.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TeaserSection } from '../../layout/TeaserSection/TeaserSection';
import { WhiteBox } from '../../layout/WhiteBox/WhiteBox';
import { sizeType, Thumbnail } from '../../ui/Thumbnail/Thumbnail';
import { HeadLineM } from '../../ui/HeadLines/HeadLineM/HeadLineM';
import { ISalary, Salary } from './Salary';
import { LinkButton, EArrowPosition } from '../../ui/Buttons/LinkButton/LinkButton';
import { IconProfile } from '../../../../assets/icons/IconProfile';
import { JobOffersTeaserHeader } from './JobOffersTeaserHeader/JobOffersTeaserHeader';
import { getClassList } from '../../../utils/getClassList';
import { pushGtmEvent } from '../../../utils/pushGtmEvent';
import { JobOffersTeaserItem } from './JobOffersTeaserItem/JobOffersTeaserItem';

interface IJobOffersTeaserProps {
    listType: string;
    profileUpdateUrl: string;
    jobOffersList: TJobOfferData[];
    whiteBackground?: boolean;
}

type TJobOfferData = {
    name: string,
    companyName: string,
    regions: string[],
    jobOfferUrl: string,
    imgUrl?: string,
    salary?: ISalary,
    isOnlineRecruitment: boolean,
    isRemoteWork: boolean,
}

const JobOffersTeaser: React.FC<IJobOffersTeaserProps> = ({ listType, jobOffersList, profileUpdateUrl, whiteBackground }) => {
    const { t } = useTranslation();
    const jobOffersAmount = jobOffersList.length;

    const classList = getClassList([
        'job-offers-teaser',
        whiteBackground ? 'job-offers-teaser--white-background' : ''
    ]);

    const pushClickEvent = () => {
        pushGtmEvent(
            'JobOffersList',
            'Click',
            'JobListItem',
            '',
            false
        )
    };

    return (
        <TeaserSection
            className={classList}
            buttonData={{
                children: t('jobOffers:seeMoreOffers'),
                url: t('paths:uri:jobOffers')
            }}
        >
            <JobOffersTeaserHeader listType={listType} />
            <WhiteBox className="job-offers-teaser__content">
                <ul className="job-offers-teaser__list">
                    {jobOffersList.map((jobOffer, index) => {
                        const { imgUrl, name, salary, companyName, regions, jobOfferUrl, isOnlineRecruitment, isRemoteWork } = jobOffer;

                        return (
                            <li
                                className="job-offers-teaser__list-item"
                                key={jobOfferUrl}
                            >
                                <a
                                    onClick={pushClickEvent}
                                    href={jobOfferUrl}
                                    className="job-offers-teaser__offer-link-wrap"
                                >
                                    <div className="job-offers-teaser__thumb">
                                        {imgUrl && (
                                            <Thumbnail
                                                size={sizeType.SMALL}
                                                imgData={{
                                                    url: imgUrl,
                                                    name: name
                                                }}
                                            />
                                        )}
                                        {!imgUrl &&
                                            <Thumbnail size={sizeType.SMALL} />
                                        }
                                    </div>
                                    <JobOffersTeaserItem
                                        className="job-offers-teaser__item-wrap"
                                        linkText={t('jobOffers:seeAndApply')}
                                        headline={name}
                                        last={index === jobOffersAmount-1}
                                        isOnlineRecruitment={isOnlineRecruitment}
                                        isRemoteWork={isRemoteWork}
                                    >
                                        {salary && <Salary {...salary} />}
                                        {companyName} {regions.map((region) => `• ${ region } `)}
                                    </JobOffersTeaserItem>
                                </a>
                            </li>
                        );
                    })}
                </ul>
                <div className="profile-update-teaser">
                    <div className="profile-update-teaser__thumb">
                        <figure className="profile-update-teaser__icon">
                            <IconProfile />
                        </figure>
                    </div>
                    <div className="profile-update-teaser__excerpt">
                        <HeadLineM>
                            {t('jobOffers:profileUpdateTitle')}
                        </HeadLineM>
                        <span className="job-offers-teaser__details job-offers-teaser__details--profile-update">
                            {t('jobOffers:profileUpdateText')}
                        </span>
                        <LinkButton url={profileUpdateUrl} arrowPosition={EArrowPosition.RIGHT}>
                            {t('jobOffers:profileUpdateLinkText')}
                        </LinkButton>
                    </div>
                </div>
            </WhiteBox>
        </TeaserSection>
    );
};

export { JobOffersTeaser, TJobOfferData, IJobOffersTeaserProps };
