import { SvgIcon } from './SvgIcon';
import * as React from 'react';

const IconMenuBurger: React.FC = () => {

    return (
        <SvgIcon
            name="menu-burger"
            svgXml={`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="2" y="5" width="20" height="2" rx="1" fill="#878787"/>
                    <rect x="2" y="11" width="20" height="2" rx="1" fill="#878787"/>
                    <rect x="2" y="17" width="20" height="2" rx="1" fill="#878787"/>
                </svg>
            `}
        />
    );
};

export { IconMenuBurger };
