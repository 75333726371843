import './Overlay.scss';
import * as React from 'react';

import { getClassList } from '../../../utils/getClassList';

interface IOverlay {
    onClick?: () => void,
    className?: string
}

const Overlay: React.FC<IOverlay> = ({ children, onClick, className }) => {
    const classList = getClassList([
        'overlay',
        className && className
    ]);

    return (
        <div className={classList} onClick={onClick && onClick}>
            {children}
        </div>
    );
};

export { Overlay, IOverlay };
