import './Header.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from '../../../../assets/icons/Logo';
import { MenuItemsFinder, TMenuItem } from '../../../class/MenuItemsFinder';
import { SpecialActionsFinder } from '../../../class/SpecialActionsFinder';
import { TNotification } from '../topCards/Notifications/Notifications';
import { DesktopMenu } from './DesktopMenu/DesktopMenu';
import { ProfileNav } from './ProfileNav/ProfileNav';
import { MobileNav } from './MobileNav/MobileNav';
import { NavItem } from './NavItem/NavItem';

interface IHeaderProps {
    userData: TUserData | null;
    inboxData: TInboxData | null;
    notificationsData: TNotificationsData | null;
}

type TUserData = {
    userProfileUrlname: string;
    userProfilePhotoUrl: string | null;
    isHrSearchActive: boolean
}

type TInboxData = {
    messagesAmount: number;
}

type TNotificationsData = {
    notificationsAmount: number;
    notificationsList: TNotification[];
}

const Header: React.FC<IHeaderProps> = ({ userData, inboxData, notificationsData }) => {
    const { t } = useTranslation();

    const loggedIn = Boolean(userData);
    const hasHrs = userData ? userData.isHrSearchActive : null;
    const menuItems = new MenuItemsFinder(t, userData, hasHrs);
    const specialActions = new SpecialActionsFinder(t);
    const { forEmployers } = menuItems.dynamicMenuItems;
    const topMenuItems = menuItems.getTopMenuItems();

    return (
        <>
            <header className="header">
                {loggedIn && (
                    <div className="header__top-bar">
                        <nav>
                            <ul className="header__top-menu">
                                {
                                    topMenuItems.map((menuItem: TMenuItem) => (
                                        <li key={menuItem.label.toLowerCase()}>
                                            <NavItem url={menuItem.uri}>
                                                {menuItem.label}
                                            </NavItem>
                                        </li>
                                    ))
                                }
                            </ul>
                        </nav>
                    </div>
                )}
                <div className="header__main-bar">
                    <div className="header__mobile-nav">
                        <MobileNav loggedIn={loggedIn} />
                    </div>
                    <div className="header__logo header__logo--light">
                        <a href={t('paths:uri:domain')}>
                            <Logo />
                        </a>
                    </div>
                    <DesktopMenu menuItemsFinder={menuItems} specialActionsFinder={specialActions} />
                    <div className="header__profile-nav">
                        <ProfileNav
                            notificationsData={notificationsData}
                            inboxData={inboxData}
                            userData={userData}
                            loggedIn={loggedIn}
                            forEmployersItem={forEmployers}
                        />
                    </div>
                </div>
            </header>
        </>
    );
};

export { Header, IHeaderProps, TUserData, TInboxData, TNotificationsData };
