import { EReduxActionTypes } from '../actions';
import { ITopCardsAction } from '../actions/topCardsActions';

interface ITopCardsState {
    search: boolean;
    notifications: boolean;
}

const topCardsInitialState: ITopCardsState = {
    search: false,
    notifications: false
};

function topCardsReducer(
    state: ITopCardsState = topCardsInitialState,
    action: ITopCardsAction
): ITopCardsState {
    switch (action.type) {
        case EReduxActionTypes.SHOW_SEARCH:
            return {
                notifications: false,
                search: true
            };

        case EReduxActionTypes.HIDE_SEARCH:
            return {
                ...state,
                search: false
            };

        case EReduxActionTypes.SHOW_NOTIFICATIONS:
            return {
                search: false,
                notifications: true
            };

        case EReduxActionTypes.HIDE_NOTIFICATIONS:
            return {
                ...state,
                notifications: false
            };

        default:
            return state;
    }
}

export { topCardsReducer, ITopCardsState, topCardsInitialState }
