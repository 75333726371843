import './Footer.scss';
import * as React from 'react';
import { SocialBox } from '../../ui/SocialBox/SocialBox';
import { TMenuItem } from '../../../class/MenuItemsFinder';
import { getFooterLinks } from './getFooterLinks';
import { getLocale } from '../../../utils/getLocale';
import { useTranslation } from 'react-i18next';
import {IconFlagUk} from "../../../../assets/icons/IconFlagUk";
import {IconFlagPl} from "../../../../assets/icons/IconFlagPl";
import {IconFlagEn} from "../../../../assets/icons/IconFlagEn";

const Footer: React.FC = () => {
    const { t } = useTranslation();
    const footerLinkGroups = getFooterLinks(t);

    const renderIcon = (linkData: TMenuItem): JSX.Element|null => {
        switch(linkData.locale) {
            case 'uk':
                return <IconFlagUk />;
            case 'pl':
                return <IconFlagPl />
            case 'en':
                return <IconFlagEn />
            default:
                return null;
        }
    }

    const footerListItem = (linkData: TMenuItem): JSX.Element => (
        <li
            key={linkData.uri}
            className="footer__list-item"
        >
            <a
                href={linkData.uri}
                className="footer__link"
                onClick={linkData.onClick}
            >
                {linkData.label}
            </a>
            {renderIcon(linkData)}
        </li>
    );

    const footerFilterItem = (linkData: TMenuItem): boolean => {
        return !linkData.locale || (getLocale() !== null && getLocale() !== linkData.locale);
    };

    return (
        <footer className="footer">
            <div className="footer__content">
                <ul className="footer__link-list">
                    {
                        footerLinkGroups.first.map((link) => footerListItem(link))
                    }
                </ul>
                <ul className="footer__link-list">
                    {
                        footerLinkGroups.second.map((link) => footerListItem(link))
                    }
                </ul>
                <ul className="footer__link-list">
                    {
                        footerLinkGroups.third.map((link) => footerListItem(link))
                    }
                </ul>
                <ul className="footer__link-list">
                    {
                        footerLinkGroups.fourth.filter(footerFilterItem).map((link) => footerListItem(link))
                    }
                </ul>
                <SocialBox size={40} />
            </div>
        </footer>
    );
};

export { Footer };
