import * as React from 'react';
import {SvgIcon} from './SvgIcon';

const IconFlagEn = () => {
    return (
        <SvgIcon
            name="IconFlagEn"
            svgXml={`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_140_1730" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                        <circle cx="12" cy="12" r="12" fill="#C4C4C4"/>
                    </mask>
                    <g mask="url(#mask0_140_1730)">
                        <path d="M36.0009 0H-11.25V24.0005H36.0009V0Z" fill="#000066"/>
                        <path d="M-11.25 0V2.68333L30.7183 24.0005H36.0009V21.3173L-5.96734 8.00015e-05H-11.25L-11.25 0ZM36.0009 0V2.68325L-5.96734 24.0005H-11.25V21.3172L30.7183 0H36.0009Z" fill="white"/>
                        <path d="M8.43789 0V24.0005H16.3131V0H8.43789ZM-11.25 8.00016V16.0003H36.0009V8.00016H-11.25Z" fill="white"/>
                        <path d="M-11.25 9.60019V14.4003H36.0009V9.60019H-11.25ZM10.0129 0V24.0005H14.738V0H10.0129Z" fill="#CC0000"/>
                        <path d="M-11.25 24.0005L4.50031 16.0003H8.02209L-7.72823 24.0005H-11.25V24.0005ZM-11.25 0L4.50031 8.00016H0.978544L-11.25 1.78892V0L-11.25 0ZM16.7289 8.00016L32.4792 0H36.0009L20.2506 8.00016H16.7289ZM36.0009 24.0005L20.2506 16.0003H23.7724L36.0009 22.2116V24.0005Z" fill="#CC0000"/>
                    </g>
                    <circle cx="12" cy="12" r="11.75" stroke="#DADADA" stroke-width="0.5"/>
                </svg>
            `}
        />
    );
};

export { IconFlagEn };
