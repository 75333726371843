import './LiveSearch.scss';

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { HolmesSearch, TSearchResults } from '../../../../class/HolmesSearch/HolmesSearch';
import { Logger } from '../../../../class/Logger';
import { SearchQueryInput } from '../../SearchQueryInput/SearchQueryInput';
import { IconUser } from '../../../../../assets/icons/IconUser';
import { IconCompany } from '../../../../../assets/icons/IconCompany';
import { isInLastString } from '../../../../utils/isInLastString';
import { CareerCenterTeaser } from './CareerCenterTeaser/CareerCenterTeaser';
import { useDispatch, useSelector } from 'react-redux';
import { IGoldenAppState } from '../../../../store/rootReducer';
import { Overlay } from '../../../ui/Overlay/Overlay';
import { hideSearch, showSearch } from '../../../../store/actions/topCardsActions';
import { setLiveSearchQuery, setSearchQueryIsLoading } from '../../../../store/actions/liveSearchActions';
import { Link } from '../../../ui/Link/Link';
import { LoadingOverlay } from '../../../ui/LoadingOverlay/LoadingOverlay';

interface ILiveSearch {
    apiKey: string;
    tagFilters: string;
    hasHrs: boolean;
}

const logger = Logger.getInstance();

const LiveSearch: React.FC<ILiveSearch> = ({ apiKey, tagFilters, hasHrs }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ searchResults, setSearchResults ] = useState<TSearchResults>({
        firms: [],
        users: [],
        jobs: []
    });
    const holmesSearchRef = useRef<HolmesSearch>();
    const resultsTimeoutRef = useRef<number>();
    const searchVisible = useSelector<IGoldenAppState, any>(state => state.topCards.search);
    const searchQuery = useSelector<IGoldenAppState, any>(state => state.liveSearch.query);
    const isLoading = useSelector<IGoldenAppState, any>(state => state.liveSearch.isLoading);

    const { firms, users, jobs } = searchResults;
    const queryIsEmpty = searchQuery === '';
    const noResults = jobs.length === 0 && firms.length === 0 && users.length === 0;

    useEffect(() => {
        holmesSearchRef.current = new HolmesSearch(apiKey, tagFilters);
    }, []);

    const fetchSearchResults = async () => {
        const holmesSearch = holmesSearchRef.current;
        if (queryIsEmpty) {
            setSearchResults({
                firms: [],
                users: [],
                jobs: []
            })
        } else if (holmesSearch) {
            dispatch(setSearchQueryIsLoading(true));
            try {
                const searchResults = await holmesSearch.getSearchResults(searchQuery);
                setSearchResults(searchResults);
                dispatch(setSearchQueryIsLoading(false))
            } catch (e) {
                dispatch(setSearchQueryIsLoading(false));
                logger.logError('[LiveSearch] HolmesSearch results failed - ', e);
            }
        } else {
            logger.logError('[LiveSearch] HolmesSearch is not defined!');
        }
    };

    useEffect(() => {
        window.clearTimeout(resultsTimeoutRef.current);
        resultsTimeoutRef.current = window.setTimeout(() => {
            fetchSearchResults();
        }, 400);

        if (!searchVisible && !queryIsEmpty) {
            dispatch(showSearch());
        } else if (searchVisible && queryIsEmpty) {
            dispatch(hideSearch());
        }
    }, [ searchQuery ]);

    const userPhotoIsMissing = (photoUrl: string): boolean => {
        return isInLastString(photoUrl, 'user_woman_photo') || isInLastString(photoUrl, 'user_man_photo');
    };
    const firmLogoIsMissing = (photoUrl: string): boolean => {
        return isInLastString(photoUrl, 'firm_logo');
    };
    const jobLogoIsMissing = (photoUrl: string): boolean => {
        return isInLastString(photoUrl, 'job_ad_logo');
    };

    const handlers = {
        onOverlayClick: (): void => {
            dispatch(setLiveSearchQuery(''));
            dispatch(hideSearch());
        }
    };

    const classList = [
        'live-search',
        searchVisible ? 'live-search--visible' : 'live-search--hidden'
    ].join(' ');

    return (
        <div className={classList}>
            <div className="live-search__content">
                <div className="live-search__query-box">
                    <SearchQueryInput />
                </div>
                <div className="live-search__results-content">
                    {noResults && (
                        <div className="live-search__query-results">
                            <div className="live-search__result">
                                {!queryIsEmpty ? (
                                    <div className="live-search__no-results-info">
                                        <Link
                                            className="live-search__all-results-link"
                                            href={`${ t('paths:uri:search') }/?q=${ searchQuery }`}
                                        >
                                            <Trans i18nKey="liveSearch:searchEverywhere">
                                                string <b>{searchQuery}</b> string
                                            </Trans>
                                        </Link>
                                    </div>
                                ) : (
                                    t('liveSearch:searchHint')
                                )}
                            </div>
                        </div>
                    )}
                    <div className="live-search__query-results">
                        {isLoading && (
                            <LoadingOverlay className="live-search__results-overlay" />
                        )}
                        {jobs.length > 0 && (
                            <div className="live-search__result">
                                <span className="live-search__result-title">{t('liveSearch:jobOffers')}</span>
                                <ul className="live-search__result-list">
                                    {jobs.map(job => (
                                        <li key={job.objectID} className="live-search__list-item">
                                            <a href={job.url} className="live-search__result-item">
                                                <div className="live-search__item-thumb">
                                                    {jobLogoIsMissing(job.logo) ? (
                                                        <div className="live-search__company-icon">
                                                            <IconCompany />
                                                        </div>
                                                    ) : (
                                                        <img
                                                            className="live-search__thumb-img"
                                                            src={job.logo.replace('big','small')}
                                                            alt={job.companyName}
                                                        />
                                                    )}
                                                </div>
                                                <div className="live-search__item-content">
                                                    <span className="live-search__item-title">
                                                        {job.position}
                                                    </span>
                                                    <span className="live-search__item-info">
                                                        {job.companyName}
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                                <Link
                                    href={`${ t('paths:uri:jobOffers') }/${ searchQuery }`}
                                    className="live-search__full-results-link"
                                >
                                    {t('liveSearch:seeAllJobs')}
                                </Link>
                            </div>
                        )}
                        {firms.length > 0 && (
                            <div className="live-search__result">
                                <span className="live-search__result-title">{t('liveSearch:employers')}</span>
                                <ul className="live-search__result-list">
                                    {firms.map((firm) => (
                                        <li key={firm.objectID} className="live-search__list-item">
                                            <a href={firm.url} className="live-search__result-item">
                                                <div className="live-search__item-thumb">
                                                    {firmLogoIsMissing(firm.logo) ? (
                                                        <div className="live-search__company-icon">
                                                            <IconCompany />
                                                        </div>
                                                    ) : (
                                                        <img
                                                            className="live-search__thumb-img"
                                                            src={firm.logo.replace('big', 'small')}
                                                            alt={firm.name}
                                                        />
                                                    )}
                                                </div>
                                                <div className="live-search__item-content">
                                                    <span className="live-search__item-title">
                                                        {firm.name}
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                                <Link
                                    href={`${ t('paths:uri:employerProfilesSearch') }/?q=${ searchQuery }`}
                                    className="live-search__full-results-link"
                                >
                                    {t('liveSearch:seeAllPeopleOrEmployers')}
                                </Link>
                            </div>
                        )}
                        {users.length > 0 && (
                            <div className="live-search__result">
                                <span className="live-search__result-title">{t('liveSearch:people')}</span>
                                <ul className="live-search__result-list">
                                    {users.map((user) => (
                                        <li key={user.objectID} className="live-search__list-item">
                                            <a href={user.url} className="live-search__result-item">
                                                <div className="live-search__item-thumb live-search__item-thumb--user">
                                                    {userPhotoIsMissing(user.photo) ? (
                                                        <IconUser />
                                                    ) : (
                                                        <img
                                                            className="live-search__thumb-img"
                                                            src={user.photo.replace('huge', 'big')}
                                                            alt={`${ user.name } ${ user.surname }`}
                                                        />
                                                    )}
                                                </div>
                                                <div className="live-search__item-content">
                                                    <span className="live-search__item-title">
                                                        {user.name} {user.surname}
                                                    </span>
                                                    <span className="live-search__item-info">
                                                        {user.headline}
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                                {hasHrs ? (
                                    <form action={t('paths:url:hrsPeopleSearch')} method="POST">
                                        <input type="hidden" name="query" value={`${ searchQuery }`} />
                                        <Link
                                            className="live-search__full-results-link"
                                            inForm
                                        >
                                            {t('liveSearch:seeAllPeopleOrEmployers')}
                                        </Link>
                                    </form>
                                ) : (
                                    <Link
                                        href={t('paths:url:businessOffer')}
                                        className="live-search__full-results-link"
                                        blank
                                    >
                                        {t('liveSearch:seeAllPeopleOrEmployers')}
                                    </Link>
                                )}
                            </div>
                        )}
                        <CareerCenterTeaser />
                    </div>
                </div>
            </div>
            <Overlay
                className="live-search__overlay"
                onClick={handlers.onOverlayClick}
            />
        </div>
    );
};

export { LiveSearch, ILiveSearch };
