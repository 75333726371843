import { EReduxActionTypes, IReduxBaseAction } from '../actions';

interface ILiveSearchQueryAction extends IReduxBaseAction{
    type: EReduxActionTypes.SET_LIVESEARCH_QUERY,
    query: string
}

interface ILiveSearchIsLoadingAction extends IReduxBaseAction {
    type: EReduxActionTypes.SET_LIVESEARCH_ISLOADING,
    isLoading: boolean
}

type TLiveSearchActions = ILiveSearchQueryAction | ILiveSearchIsLoadingAction

function setLiveSearchQuery(newValue: string): ILiveSearchQueryAction {
    return {
        type: EReduxActionTypes.SET_LIVESEARCH_QUERY,
        query: newValue
    }
}

function setSearchQueryIsLoading(isLoading: boolean): ILiveSearchIsLoadingAction {
    return {
        type: EReduxActionTypes.SET_LIVESEARCH_ISLOADING,
        isLoading: isLoading
    }
}

export { setLiveSearchQuery, setSearchQueryIsLoading, TLiveSearchActions }
