import { EReduxActionTypes } from '../actions';
import { IMobileMenuAction } from '../actions/mobileMenuActions';

const mobileMenuInitialState = false;

function mobileMenuReducer(state = mobileMenuInitialState, action: IMobileMenuAction): boolean {
    switch (action.type) {
        case EReduxActionTypes.SHOW_MOBILE_MENU:
            return true;

        case EReduxActionTypes.HIDE_MOBILE_MENU:
            return false;

        default:
            return state;
    }
}

export { mobileMenuReducer, mobileMenuInitialState }
