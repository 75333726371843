import './TheSun.scss';
import * as React from 'react';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { getClassList } from '../../../utils/getClassList';

interface ITheSun {
    parallax: boolean,
    className?: string
}

type TSunPosition = {
    x: number,
    y: number
}

const TheSun: React.FC<ITheSun> = ({ className, parallax }) => {
    const classList = getClassList([
        'the-sun',
        className ? className : '',
        parallax ? 'the-sun--px' : ''
    ]);

    const percentageRef = useRef(0);
    const domSunRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
    const scrollMaxRef = useRef<number | null>(null);
    const widthRef = useRef<number | null>(null);
    const topRef = useRef(0);
    const rightRef = useRef(0);
    
    const shiftModifierRef = useRef<number | null>();

    const [ position, setPosition ] = useState<TSunPosition>({
        x: 0,
        y: 0
    });

    const onScroll = useCallback(() => {
        const viewportOffset = document.body.getBoundingClientRect();
        const scrollPosition = viewportOffset.top * -1;
        const scrollMax = scrollMaxRef.current;
        const shiftModifier = shiftModifierRef.current;
        const width = widthRef.current;

        if (typeof scrollMax === 'number' && typeof shiftModifier === 'number' && typeof width === 'number') {
            if (scrollPosition * 100 / scrollMax === 0) {
                percentageRef.current = 0.1;
            } else {
                percentageRef.current = scrollPosition * 100 / scrollMax;
            }

            const percentage = percentageRef.current;

            const startX = rightRef.current;
            const startY = topRef.current;

            const yShift = percentage / 100 * shiftModifier;
            const xShift = Math.sin(percentage * Math.PI / 100) * 0.3;

            setPosition({
                x: startX + xShift * width ,
                y: startY + yShift * scrollMax
            });
        } else {
            console.log('Error, no scrolll max');
        }
    }, []);

    useEffect(() => {
        // Shift
        const viewportHeight = document.documentElement.clientHeight;
        const documentHeight = document.body.offsetHeight;
        const viewportWidth = document.documentElement.clientWidth;

        scrollMaxRef.current = documentHeight - viewportHeight ;
        shiftModifierRef.current = documentHeight / scrollMaxRef.current;
        widthRef.current = viewportWidth;

        // Size and position
        if (domSunRef.current) {
            let startX = 0;
            let startY = 0;
            if (widthRef.current < 1140 ) {
                startX = -1 * domSunRef.current.offsetHeight * 0.66;
                startY = -1 * domSunRef.current.offsetHeight * 0.75;
            } else {
                startX = -1 * domSunRef.current.offsetHeight * 0.75;
                startY = -1 * domSunRef.current.offsetHeight * 0.50;
            }
            rightRef.current = startX;
            topRef.current = startY;

            setPosition({
                x: startX,
                y: startY
            });
        }

        // Event
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [ onScroll ]);

    const { x, y } = position;

    return (
        <div className={classList}>
            <div className="the-sun__track">
                <div
                    className="the-sun__shine"
                    ref={domSunRef}
                    style={{
                        top: `${ y }px`,
                        right: `${ x }px`,
                        visibility: position.x !== 0 && position.y !== 0 ? 'visible' : 'hidden'
                    }}
                />
            </div>
        </div>
    );
};

export { TheSun };
