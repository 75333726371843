import * as React from 'react';
import { SvgIcon } from './SvgIcon';

const IconCompany = () => {
    return (
        <SvgIcon
            name="search"
            svgXml={`
                <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.3867 23.5H20.3867V2.191C20.3867 1.535 19.8527 1 19.1967 1H10.4087C9.75273 1 9.21973 1.535 9.21973 2.191V7.182H8.21973V2.191C8.21973 0.983 9.20173 0 10.4087 0H19.1967C20.4047 0 21.3867 0.983 21.3867 2.191V23.5Z" fill="#B9BECB"/>
                <path d="M18.1093 24.0001H10.8683V19.6421H7.83726V24.0001H0.614258V8.94908C0.614258 7.70008 1.63126 6.68408 2.88026 6.68408H15.8443C17.0933 6.68408 18.1103 7.70008 18.1103 8.94908V24.0001H18.1093ZM11.8683 23.0001H17.1093V8.94908C17.1093 8.25108 16.5413 7.68408 15.8433 7.68408H2.88026C2.18226 7.68408 1.61426 8.25008 1.61426 8.94908V23.0001H6.83826V18.6421H11.8693V23.0001H11.8683Z" fill="#B9BECB"/>
                <path d="M13.4681 3.45093H11.4131V4.45093H13.4681V3.45093Z" fill="#B9BECB"/>
                <path d="M15.428 9.80688H13.373V10.8069H15.428V9.80688Z" fill="#B9BECB"/>
                <path d="M10.3714 9.80688H8.31641V10.8069H10.3714V9.80688Z" fill="#B9BECB"/>
                <path d="M5.31379 9.80688H3.25879V10.8069H5.31379V9.80688Z" fill="#B9BECB"/>
                <path d="M15.428 13.645H13.373V14.645H15.428V13.645Z" fill="#B9BECB"/>
                <path d="M10.3714 13.645H8.31641V14.645H10.3714V13.645Z" fill="#B9BECB"/>
                <path d="M5.31379 13.645H3.25879V14.645H5.31379V13.645Z" fill="#B9BECB"/>
                <path d="M15.428 17.072H13.373V18.072H15.428V17.072Z" fill="#B9BECB"/>
                <path d="M5.31379 17.072H3.25879V18.072H5.31379V17.072Z" fill="#B9BECB"/>
                <path d="M17.6097 3.45093H15.5547V4.45093H17.6097V3.45093Z" fill="#B9BECB"/>
                </svg>
            `}
        />
    );
};

export { IconCompany }
