import './SearchQueryInput.scss';
import * as React from 'react';
import { useState, ChangeEvent, KeyboardEvent, useRef, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { hideSearch } from '../../../store/actions/topCardsActions';
import { setLiveSearchQuery } from '../../../store/actions/liveSearchActions';
import { IGoldenAppState } from '../../../store/rootReducer';
import { IconSearch } from '../../../../assets/icons/IconSearch';
import { IconArrow } from '../../../../assets/icons/IconArrow';
import { Logger } from '../../../class/Logger';
import { getClassList } from '../../../utils/getClassList';
const logger = Logger.getInstance();

interface ISearchQueryInput {
    compact?: boolean
}

const SearchQueryInput: React.FC<ISearchQueryInput> = ({ compact }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const query = useSelector<IGoldenAppState, any>(state => state.liveSearch.query);
    const [ inputOnFocus, setInputOnFocus ] = useState(false);
    const fullPageLinkRef: RefObject<HTMLAnchorElement> | null = useRef(null);
    const queryInput: RefObject<HTMLInputElement> | null = useRef(null);

    const classList = getClassList([
        'search-query-input',
        inputOnFocus && 'search-query-input--focused',
        compact && 'search-query-input--compact'
    ]);

    const setQuery = (newValue: string): void => {
        dispatch(setLiveSearchQuery(newValue))
    };

    const handlers = {
        onInputChange: (event: ChangeEvent<HTMLInputElement>): void => {
            const newValue = event.target.value;
            setQuery(newValue);
        },
        onInputFocus: (): void => {
            setInputOnFocus(true);
        },
        onInputBlur: (): void => {
            setInputOnFocus(false);
        },
        onInputKeyDown: (event: KeyboardEvent<HTMLInputElement>): void => {
            switch (event.key) {
                case 'Enter':
                    if (fullPageLinkRef.current) {
                        fullPageLinkRef.current.click();
                    } else {
                        logger.logError('[LiveSearch] FullPageLink is not available!')
                    }
                    break;
                case 'Escape':
                    setQuery('');
                    dispatch(hideSearch());
                    if (queryInput.current) {
                        queryInput.current.blur();
                    } else {
                        logger.logError('[LiveSearch] QueryInput is not available!')
                    }
                    break;
                default:
                    break;
            }
        },
        onCloseClick: (): void => {
            setQuery('');
            dispatch(hideSearch());
        }
    };

    return (
        <div className={classList}>
            {!compact && (
                <button
                    className="search-query-input__close"
                    type="button"
                    onClick={handlers.onCloseClick}
                >
                    <IconArrow />
                </button>
            )}
            <input
                className="search-query-input__text-input"
                type="text"
                value={query}
                ref={queryInput}
                onChange={handlers.onInputChange}
                onFocus={handlers.onInputFocus}
                onBlur={handlers.onInputBlur}
                onKeyDown={handlers.onInputKeyDown}
            />
            <a
                className="search-query-input__full-page-link"
                href={`${ t('paths:uri:search') }/?q=${ encodeURIComponent(query) }`}
                title={t('liveSearch:search')}
                ref={fullPageLinkRef}
            >
                <IconSearch />
            </a>
        </div>
    );
};

export { SearchQueryInput };
