import './MobileNav.scss';
import * as React from 'react';
import { IconMenuBurger } from '../../../../../assets/icons/IconMenuBurger';
import { IconSearch } from '../../../../../assets/icons/IconSearch';
import { hideMobileMenu, showMobileMenu } from '../../../../store/actions/mobileMenuActions';
import { useDispatch, useSelector } from 'react-redux';
import { IGoldenAppState } from '../../../../store/rootReducer';
import { hideSearch, showSearch } from '../../../../store/actions/topCardsActions';
import { NavIcon } from '../NavIcon/NavIcon';

interface IMobileNav {
    loggedIn: boolean
}

const MobileNav: React.FC<IMobileNav> = ({ loggedIn }) => {
    const dispatch = useDispatch();
    const mobMenuVisible = useSelector<IGoldenAppState, {}>(state => state.mobileMenuVisible);
    const searchVisible = useSelector<IGoldenAppState, {}>(state => state.topCards.search);

    const handlers = {
        searchClick: (): void => {
            if (searchVisible) {
                dispatch(hideSearch());
            } else {
                dispatch(showSearch());
            }
        },
        menuBurgerClick: (): void => {
            if (mobMenuVisible) {
                dispatch(hideMobileMenu())
            } else {
                dispatch(showMobileMenu())
            }
        }
    };

    return (
        <nav className="mobile-nav">
            <NavIcon onClick={handlers.menuBurgerClick}>
                <IconMenuBurger />
            </NavIcon>
            {loggedIn && (
                <NavIcon onClick={handlers.searchClick}>
                    <IconSearch />
                </NavIcon>
            )}
        </nav>
    );
};

export { MobileNav, IMobileNav };
