import { AlgoliaQuery, IAlgoliaQuery, TQueryData } from './AlgoliaQuery';

/**
 *  User - Possible fields
 *
 *  @string headline
 *  @long objectID - Nie używać w indexFields, zawsze jest zwracane domyślnie w results
 *  @string name
 *  @long counter
 *  @string surname
 *  @string _tags
 *  @string urlname
 *  @string photo
 *  @string url
 */

type TUserData = {
    objectID: string,
    name: string,
    surname: string,
    photo: string,
    headline: string,
    url: string
}

function getUserQueries(query: string, userFilter: string): { 0: IAlgoliaQuery, 1: IAlgoliaQuery } {
    const algoliaQueryParams: TQueryData = {
        indexName: 'users',
        indexFields: 'name,surname,photo,headline,url',
        userFilter: userFilter,
        query: query,
    };

    const privateUserQuery: IAlgoliaQuery = new AlgoliaQuery(algoliaQueryParams, false);
    const publicUserQuery: IAlgoliaQuery = new AlgoliaQuery(algoliaQueryParams, true);

    return [
        privateUserQuery,
        publicUserQuery
    ];
}

export { getUserQueries, TUserData };
