import * as React from 'react';
import { hydrate } from 'react-dom';
import { Logger } from '../class/Logger';
import { initI18n } from '../translations/i18next';
import { IWindow } from '../typings/IWindow';

declare const pageProps: { [key: string]: string };
declare const window: IWindow & Window;

const loadPage = (ReactComponent: React.FC): void => {
    document.addEventListener('DOMContentLoaded', function() {
        const twigContainer = document.getElementById('react-page');
        if (twigContainer !== null) {
            const reactContainer = twigContainer.getElementsByTagName('div')[0];

            const lang = window.LOCALE ? window.LOCALE : 'pl';
            initI18n(lang);

            hydrate(React.createElement(ReactComponent, pageProps), reactContainer);
        } else {
            const logger = Logger.getInstance();
            logger.logError('twigContainer not available!');
        }
    });
};

export { loadPage };
