type IAlgoliaQuery = {
    indexName: string;
    query: string;
    params: TQueryParams
};

type TQueryData = {
    query: string,
    userFilter: string,
    indexName: string,
    indexFields: string
}

type TQueryParams = {
    hitsPerPage: number,
    attributesToRetrieve: string,
    tagFilters: string
}

class AlgoliaQuery implements IAlgoliaQuery {
    public query: string;
    public indexName: string;
    public params: TQueryParams;

    constructor(queryData: TQueryData, publicQuery: boolean) {
        const { query, userFilter, indexName, indexFields } = queryData;
        this.query = query;
        this.indexName = indexName;
        this.params = {
            hitsPerPage: 3,
            attributesToRetrieve: indexFields,
            tagFilters: this.getTagFilters(userFilter, publicQuery)
        };
    }

    private getTagFilters(userFilter: string, publicQuery: boolean): string {
        return publicQuery ? `public,-${ userFilter }` : userFilter;
    }
}

export { AlgoliaQuery, IAlgoliaQuery, TQueryData };
