import './ProfileNav.scss';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AmountBox } from '../../../ui/AmountBox/AmountBox';
import { IconMessage } from '../../../../../assets/icons/IconMessage';
import { IconAlert } from '../../../../../assets/icons/IconAlert';
import { buttonSize, DefaultButton } from '../../../ui/Buttons/DefaultButton/DefaultButton';
import { TInboxData, TNotificationsData, TUserData } from '../Header';
import { hideNotifications, hideSearch, showNotifications, showSearch } from '../../../../store/actions/topCardsActions';
import { IGoldenAppState } from '../../../../store/rootReducer';
import { TMenuItem } from '../../../../class/MenuItemsFinder';
import { NavIcon } from '../NavIcon/NavIcon';
import { NavItem } from '../NavItem/NavItem';
import { UserPhoto } from './UserPhoto/UserPhoto';
import { SearchQueryInput } from '../../SearchQueryInput/SearchQueryInput';
import { IconUser } from '../../../../../assets/icons/IconUser';
import { Logger } from '../../../../class/Logger';

interface IProfileNav {
    loggedIn: boolean,
    inboxData: TInboxData | null,
    notificationsData: TNotificationsData | null,
    userData: TUserData | null,
    forEmployersItem: TMenuItem
}

const logger = Logger.getInstance();

const ProfileNav: React.FC<IProfileNav> = ({ loggedIn, inboxData, notificationsData, userData, forEmployersItem }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const notificationsVisible = useSelector<IGoldenAppState, any>(state => state.topCards.notifications);
    const searchVisible = useSelector<IGoldenAppState, any>(state => state.topCards.search);

    const actions = {
        toggleNotifications: (): void => {
            const notificationsAvailable = notificationsData && notificationsData.notificationsAmount;

            if (notificationsAvailable && notificationsVisible) {
                dispatch(hideNotifications());
            } else if (notificationsAvailable && !notificationsVisible) {
                dispatch(showNotifications());
            } else {
                logger.logError('[ProfileNav] Notifications toggle should be disabled without notifiactions');
            }
        },
        toggleSearch: (): void => {
            if (searchVisible) {
                dispatch(hideSearch());
            } else {
                dispatch(showSearch());
            }
        }
    };

    const handlers = {
        notificationsClick: actions.toggleNotifications,
        searchClick: actions.toggleSearch,
    };

    return (
        <nav className="profile-nav">
            {loggedIn ? (
                <>
                    <div className="profile-nav__search-query">
                        <SearchQueryInput compact />
                    </div>
                    {inboxData && (
                        <NavIcon>
                            <a
                                href={t('paths:uri:messages')}
                                className="profile-nav__nav-icon"
                            >
                                <AmountBox amount={inboxData.messagesAmount}>
                                    <IconMessage />
                                </AmountBox>
                            </a>
                        </NavIcon>
                    )}
                    {notificationsData && (
                        <NavIcon
                            className="profile-nav__nav-icon notifications-toggle"
                            onClick={handlers.notificationsClick}
                            disabled={notificationsData.notificationsAmount <= 0}
                        >
                            <AmountBox amount={notificationsData.notificationsAmount}>
                                <IconAlert />
                            </AmountBox>
                        </NavIcon>
                    )}
                    {userData && (
                        <a
                            className="profile-nav__profile-link"
                            href={`/${ userData.userProfileUrlname }`}
                            title={t('header:nav:myProfile')}
                        >
                            <div className="profile-nav__user-photo">
                                {userData.userProfilePhotoUrl ? (
                                    <UserPhoto imgUrl={userData.userProfilePhotoUrl} />
                                ) : (
                                    <IconUser />
                                )}
                            </div>
                        </a>
                    )}
                </>
            ) : (
                <>
                    <div className="profile-nav__login-box--mobile">
                        <a
                            className="profile-nav__login-btn"
                            href={t('paths:uri:logIn')}
                        >
                            {t('header:nav:logIn')}
                        </a>
                    </div>
                    <div className="profile-nav__login-box--desktop">
                        <div className="profile-nav__for-employers-btn">
                            <NavItem url={forEmployersItem.uri} target="_blank">
                                {forEmployersItem.label}
                            </NavItem>
                        </div>
                        <DefaultButton url={t('paths:uri:logIn')} size={buttonSize.S}>
                            {t('header:nav:logIn')}
                        </DefaultButton>
                    </div>
                </>
            )}
        </nav>
    );
};

export { ProfileNav, IProfileNav };
