import './EcommerceTeaser.scss';

import * as React from 'react';
import { HeadLineXL } from '../../ui/HeadLines/HeadLineXL/HeadLineXL';
import { useTranslation } from 'react-i18next';
import { WhiteBox } from '../../layout/WhiteBox/WhiteBox';
import {EArrowPosition, LinkButton} from '../../ui/Buttons/LinkButton/LinkButton';
import { EcommerceJobAds } from '../../../../assets/icons/EcommerceJobAds';
import { EcommerceHrs } from '../../../../assets/icons/EcommerceHrs';

const EcommerceTeaser: React.FC = () => {
    const { t } = useTranslation();

    return (
        <section className="ecommerce-teaser">
            <div className="ecommerce-teaser__content-wrapper">
                <header className="ecommerce-teaser__header">
                    <HeadLineXL>{ t('ecommerceTeaser:header:p1') }</HeadLineXL>
                    <HeadLineXL>{ t('ecommerceTeaser:header:p2') }</HeadLineXL>
                </header>

                <WhiteBox className="ecommerce-teaser__box">
                    <div className="ecommerce-teaser__svg-wrap">
                        <EcommerceHrs />
                    </div>
                    <div className="ecommerce-teaser__description">
                        { t('ecommerceTeaser:hrsTeaserDescription') }
                    </div>
                    <LinkButton url={t('paths:url:shop:candidateSearch')} arrowPosition={EArrowPosition.RIGHT}>
                        { t('ecommerceTeaser:hrsTeaserLinkText') }
                    </LinkButton>
                </WhiteBox>

                <WhiteBox className="ecommerce-teaser__box">
                    <div className="ecommerce-teaser__svg-wrap">
                        <EcommerceJobAds />
                    </div>
                    <div className="ecommerce-teaser__description">
                        { t('ecommerceTeaser:jobAdsTeaserDescription') }
                    </div>
                    <LinkButton url={t('paths:url:shop:jobAdPublish')} arrowPosition={EArrowPosition.RIGHT}>
                        { t('ecommerceTeaser:jobAdsTeaserLinkText') }
                    </LinkButton>
                </WhiteBox>
            </div>
        </section>
    )
};

export { EcommerceTeaser }
