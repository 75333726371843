import { TFunction } from 'i18next';
import { TMenuItem } from '../../../class/MenuItemsFinder';

type TfooterLinks = {
    first: TMenuItem[];
    second: TMenuItem[];
    third: TMenuItem[];
    fourth: TMenuItem[];
};

declare global {
    // eslint-disable-next-line @typescript-eslint/interface-name-prefix
    interface Window {
        OneTrust: any;
    }
}

const getFooterLinks = (t: TFunction): TfooterLinks => {
    return {
        first: [
            {
                label: t('footer:jobOffers'),
                uri: t('paths:uri:jobOffersSearch'),
            },
            {
                label: t('footer:companiesReviews'),
                uri: t('paths:uri:companies'),
            },
            {
                label: t('footer:companiesProfiles'),
                uri: t('paths:uri:employerProfiles'),
            },
            {
                label: t('footer:careerCenter'),
                uri: t('paths:uri:careerCenter'),
            },
        ],
        second: [
            {
                label: t('footer:forCompanies'),
                uri: t('paths:url:businessOffer'),
            },
            {
                label: t('footer:blog'),
                uri: t('paths:url:blog'),
            },
            {
                label: t('footer:career'),
                uri: t('paths:uri:career'),
            },
            {
                label: t('footer:privacy'),
                uri: t('paths:uri:privacy'),
            },
            {
                label: t('footer:shop'),
                uri: t('paths:url:shop:homepage'),
            },
        ],
        third: [
            {
                label: t('footer:rodo'),
                uri: t('paths:url:rodo'),
            },
            {
                label: t('footer:terms'),
                uri: t('paths:uri:terms'),
            },
            {
                label: t('footer:transparencyPolicy'),
                uri: t('paths:uri:transparencyPolicy'),
            },
            {
                label: t('footer:myConsents'),
                onClick: () => {
                    if (window.OneTrust) {
                        window.OneTrust.ToggleInfoDisplay();
                    }

                    return false;
                },
                uri: '#',
            },
            {
                label: t('footer:productsRegulations'),
                uri: t('paths:uri:productsRegulations'),
            },
        ],
        fourth: [
            {
                label: t('footer:contact'),
                uri: t('paths:uri:contact'),
            },
            {
                label: t('footer:help'),
                uri: t('paths:url:help'),
            },
            {
                label: t('footer:versionPl'),
                locale: 'pl',
                uri: t('paths:uri:setLocaleToPl'),
            },
            {
                label: t('footer:versionEn'),
                locale: 'en',
                uri: t('paths:uri:setLocaleToEn'),
            },
            {
                label: t('footer:versionUk'),
                locale: 'uk',
                uri: t('paths:uri:setLocaleToUk'),
            },
        ],
    };
};

export { getFooterLinks };
