import * as React from 'react';
import {SvgIcon} from './SvgIcon';

const IconFlagPl = () => {
    return (
        <SvgIcon
            name="IconFlagPl"
            svgXml={`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_140_1731" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                        <circle cx="12" cy="12" r="11.5" fill="#C4C4C4" stroke="black"/>
                    </mask>
                    <g mask="url(#mask0_140_1731)">
                        <rect x="-4" width="32" height="12" fill="white"/>
                        <rect x="-4" y="12" width="32" height="12" fill="#CC0000"/>
                        <circle cx="12" cy="12" r="11.75" stroke="#DADADA" stroke-width="0.5"/>
                    </g>
                </svg>
            `}
        />
    );
};

export { IconFlagPl };
