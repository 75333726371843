import { EReduxActionTypes, IReduxBaseAction } from '../actions';

interface ITopCardsAction extends IReduxBaseAction {
    type: EReduxActionTypes.SHOW_SEARCH |
        EReduxActionTypes.HIDE_SEARCH |
        EReduxActionTypes.SHOW_NOTIFICATIONS |
        EReduxActionTypes.HIDE_NOTIFICATIONS
}

function showSearch(): ITopCardsAction {
    return {
        type: EReduxActionTypes.SHOW_SEARCH
    };
}

function hideSearch(): ITopCardsAction {
    return {
        type: EReduxActionTypes.HIDE_SEARCH
    }
}

function showNotifications(): ITopCardsAction {
    return {
        type: EReduxActionTypes.SHOW_NOTIFICATIONS
    }
}

function hideNotifications(): ITopCardsAction {
    return {
        type: EReduxActionTypes.HIDE_NOTIFICATIONS
    }
}

export { showSearch, hideSearch, showNotifications, hideNotifications, ITopCardsAction };
