import * as React from 'react';
import { SvgIcon } from './SvgIcon';

const IconGoogleShield = () => {
    return (
        <SvgIcon
            name="google-shield"
            svgXml={`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                <path d="M8.3563 13.3656L7.90062 15.0667L6.23514 15.1019C5.7374 14.1788 5.45508 13.1225 5.45508 12.0001C5.45508 10.9147 5.71904 9.89112 6.18694 8.98987H6.1873L7.67005 9.26171L8.31958 10.7356C8.18364 11.1319 8.10954 11.5573 8.10954 12.0001C8.10959 12.4805 8.19663 12.9409 8.3563 13.3656Z" fill="#FBBB00"/>
                <path d="M18.4317 10.7772C18.5069 11.1732 18.5461 11.5821 18.5461 12C18.5461 12.4686 18.4968 12.9257 18.403 13.3666C18.0843 14.8671 17.2517 16.1772 16.0984 17.1044L16.098 17.104L14.2304 17.0087L13.9661 15.3587C14.7314 14.9099 15.3295 14.2075 15.6446 13.3666H12.1445V10.7772H15.6956H18.4317Z" fill="#518EF8"/>
                <path d="M16.0991 17.1041L16.0995 17.1044C14.9778 18.006 13.5528 18.5455 12.0017 18.5455C9.50906 18.5455 7.34187 17.1522 6.23633 15.1019L8.35749 13.3656C8.91025 14.8408 10.3333 15.891 12.0017 15.891C12.7188 15.891 13.3907 15.6971 13.9671 15.3587L16.0991 17.1041Z" fill="#28B446"/>
                <path d="M16.1771 6.96148L14.0566 8.69745C13.46 8.32452 12.7547 8.10908 11.9991 8.10908C10.293 8.10908 8.84328 9.20741 8.31821 10.7355L6.1859 8.98985H6.18555C7.2749 6.88955 9.46942 5.45459 11.9991 5.45459C13.5873 5.45459 15.0435 6.02031 16.1771 6.96148Z" fill="#F14336"/>
                </g>
                <defs>
                <clipPath id="clip0">
                <rect width="13.0909" height="13.0909" fill="white" transform="translate(5.45508 5.45459)"/>
                </clipPath>
                </defs>
                </svg>
            `}
        />
    );
};

export { IconGoogleShield }
