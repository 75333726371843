import './Notifications.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { LinkButton } from '../../../ui/Buttons/LinkButton/LinkButton';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideNotifications } from '../../../../store/actions/topCardsActions';
import { IGoldenAppState } from '../../../../store/rootReducer';
import { getClassList } from '../../../../utils/getClassList';

interface INotifications {
    notificationsList: TNotification[];
}

type TNotification = {
    text: string;
    link: string;
}

const Notifications: React.FC<INotifications> = ({ notificationsList }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const visible = useSelector<IGoldenAppState, any>( state => state.topCards.notifications);

    useEffect(() => {
        const domNotifications = document.getElementsByClassName('notifications')[0];

        document.addEventListener('click', (event: MouseEvent & { path: HTMLElement[] }) => {
            const notificationsVisible = domNotifications.classList.contains('notifications--visible');
            const eventPathElements = event.path as HTMLElement[];

            if (notificationsVisible && eventPathElements) {
                const anyElementIsToggle = eventPathElements.some((pathElement) => {
                    return pathElement.classList && pathElement.classList.contains('notifications-toggle');
                });
                const anyElementIsContent = eventPathElements.some((pathElement) => {
                    return pathElement.classList && pathElement.classList.contains('notifications');
                });

                const clickNotOnNotifications = !(anyElementIsToggle || anyElementIsContent);

                clickNotOnNotifications && dispatch(hideNotifications());
            }
        });
    }, []);

    const classList = getClassList([
        'notifications',
        visible ? 'notifications--visible' : 'notifications--hidden'
    ]);

    return (
        <div
            className={classList}
        >
            <div
                className="notifications__trigger-pointer"
                dangerouslySetInnerHTML={{ __html: `
                <svg width="41" height="20" viewBox="0 0 41 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.0505 1.84982C19.8502 0.887957 21.3267 0.887957 22.1264 1.84983L36.1769 18.75H5L19.0505 1.84982Z" fill="white"/>
                    <path d="M41 18.75H38.0532C36.8645 18.75 35.7373 18.2213 34.9774 17.3072L22.1264 1.84983C21.3267 0.887957 19.8502 0.887957 19.0505 1.84982L6.19952 17.3072C5.43957 18.2213 4.31242 18.75 3.12369 18.75H0" stroke="#F3F4F6"/>
                </svg>
            ` }}
            />
            <ul className="notifications__list">
                {notificationsList.map((notification) => (
                    <li
                        key={notification.link}
                        className="notifications__list-item"
                    >
                        <span
                            className="notifications__text"
                            dangerouslySetInnerHTML={{ __html: `
                                ${ notification.text }
                            ` }}
                        />
                        <div className="notifications__action-bar">
                            <LinkButton small url={notification.link}>
                                {t('header:notifications:check')}
                            </LinkButton>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export { Notifications, INotifications, TNotification };
