import './UserPhoto.scss';
import * as React from 'react';
import { isInLastString } from '../../../../../utils/isInLastString';
import { IconUser } from '../../../../../../assets/icons/IconUser';

interface IUserPhoto {
    imgUrl: string
}

const UserPhoto: React.FC<IUserPhoto> = ({ imgUrl }) => {
    const userPhotoIsMissing = (photoUrl: string): boolean => {
        return isInLastString(photoUrl, 'user_woman_photo') || isInLastString(photoUrl, 'user_man_photo');
    };

    return (
        <div className="user-photo">
            {userPhotoIsMissing(imgUrl) ? (
                <IconUser />
            ) : (
                <img src={imgUrl} alt="User photo" />
            )}
        </div>
    );
};

export { UserPhoto, IUserPhoto };
