import './JobOffersTeaserItem.scss';
import * as React from 'react';

import { HeadLineM } from '../../../ui/HeadLines/HeadLineM/HeadLineM';
import { EArrowPosition, LinkButton } from '../../../ui/Buttons/LinkButton/LinkButton';
import { Separator } from '../../../ui/Separator/Separator';
import { getClassList } from '../../../../utils/getClassList';
import { useTranslation } from 'react-i18next';

interface IJobOffersTeaserItem {
    linkText: string;
    headline: string;
    className?: string;
    last?: boolean
    isOnlineRecruitment: boolean;
    isRemoteWork: boolean;
}

const JobOffersTeaserItem: React.FC<IJobOffersTeaserItem> = ( { children, linkText, headline, className, last, isOnlineRecruitment, isRemoteWork } ) => {
    const { t } = useTranslation();
    const classList = getClassList([
        'job-offers-teaser-item',
        className ? className : ''
    ]);
    const hasLabel = isOnlineRecruitment || isRemoteWork;

    return (
        <div className={classList}>
            <div className="job-offers-teaser-item__excerpt">
                <div className="job-offers-teaser-item__description">
                    { hasLabel && (
                        <div className="job-offers-teaser-item__labels-wrap">
                            { isOnlineRecruitment &&
                                <span className="job-offers-teaser-item__label job-offers-teaser-item__label--online-recruitment">
                                    {t('jobOffers:jobOffersItem:onlineRecruitmentLabel')}
                                </span>
                            }
                            { isRemoteWork &&
                                <span className="job-offers-teaser-item__label job-offers-teaser-item__label--remote-work">
                                    {t('jobOffers:jobOffersItem:remoteWorkLabel')}
                                </span>
                            }
                        </div>
                    )}
                    <HeadLineM>
                        {headline}
                    </HeadLineM>
                    <span className="job-offers-teaser-item__details">
                        {children}
                    </span>
                </div>
                <div className="job-offers-teaser-item__link-button-wrap">
                    <LinkButton arrowPosition={EArrowPosition.RIGHT}>
                        {linkText}
                    </LinkButton>
                </div>
            </div>
            { !last && <Separator className="job-offers-teaser-item__separator" /> }
        </div>
    );
};

export { JobOffersTeaserItem, IJobOffersTeaserItem };
