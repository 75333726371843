import * as React from 'react';
import { SvgIcon } from './SvgIcon';

const IconFacebook = () => {
    return (
        <SvgIcon
            name="facebook"
            svgXml={`
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32 16.0001C32 7.164 24.837 0 15.9999 0C7.16301 0 0 7.164 0 16.0001C0 24.837 7.1629 32 15.9999 32C24.837 32 32 24.8371 32 16.0001ZM20.0102 11.015H17.9482C17.3932 11.015 17.0032 11.4851 17.0032 12.0711V12.99H19.9482L19.4712 15.973H17.0022V23.969H13.9882V15.973H11.9882V12.99H13.9882V11.469H13.9942C14.0473 9.735 14.6492 8.08403 17.5863 7.98001V7.96902H20.0102V11.015Z" fill="#FFE000"/>
                </svg>
            `}
        />
    );
};

export { IconFacebook };
