import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface ISalary {
    min: number,
    max: number,
    currency: string,
    type: string
}

const Salary: React.FC<ISalary> = ({ min,max,currency,type }) => {
    const { t } = useTranslation();

    const salaryTypeKey = `jobOffers:type:${ type }`;
    const salarySuffix = `${ currency } ${ t(salaryTypeKey) }`;

    return (
        <span>
            {(max === min) && `${ max } ${ salarySuffix }`}
            {(max > 0 && min > 0 && max !== min) && `${ min } - ${ max } ${ salarySuffix }`}
            {(max <= 0 && min > 0) && `${ t('jobOffers:from') } ${ min } ${ salarySuffix }`}
            {(max > 0 && min <= 0) && `${ t('jobOffers:to') } ${ max } ${ salarySuffix }`}
            <br />
        </span>
    );
};

export { Salary, ISalary };
