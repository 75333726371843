import * as React from 'react';
import { SvgIcon } from './SvgIcon';

const IconFacebookShield = () => {
    return (
        <SvgIcon
            name="facebook-shield"
            svgXml={`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.7364 5.00291L13.0574 5C11.1711 5 9.95214 6.35233 9.95214 8.44542V10.034H8.264C8.11813 10.034 8 10.1619 8 10.3196V12.6213C8 12.779 8.11826 12.9067 8.264 12.9067H9.95214V18.7145C9.95214 18.8723 10.0703 19 10.2161 19H12.4187C12.5646 19 12.6827 18.8721 12.6827 18.7145V12.9067H14.6565C14.8024 12.9067 14.9205 12.779 14.9205 12.6213L14.9213 10.3196C14.9213 10.2439 14.8935 10.1713 14.844 10.1177C14.7946 10.0641 14.7272 10.034 14.6572 10.034H12.6827V8.68734C12.6827 8.04008 12.8253 7.71151 13.6051 7.71151L14.7361 7.71107C14.8819 7.71107 15 7.58319 15 7.4256V5.28838C15 5.13094 14.882 5.0032 14.7364 5.00291Z" fill="#4267B2"/>
                </svg>
            `}
        />
    );
};

export { IconFacebookShield }
