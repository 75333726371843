import './AnonymousHomepage.scss';
import * as React from 'react';

import { EcommerceTeaser } from '../../bl/EcommerceTeaser/EcommerceTeaser';
import { RegistrationBox, IRegistrationBox } from '../../bl/RegistrationBox/RegistrationBox';
import { DefaultPage, IGlobalProps } from '../../layout/DefaultPage/DefaultPage';
import { AnonymousIntro } from '../../bl/AnonymousIntro/AnonymousIntro';
import { EPageUtmSource } from '../EPageUtmSource';
import { IJobOffersTeaserProps, JobOffersTeaser } from "../../bl/JobOffersTeaser/JobOffersTeaser";

interface IAnonymousHomepage {
    globalProps: IGlobalProps,
    registrationBoxProps: IRegistrationBox,
    jobOffersTeaserProps: IJobOffersTeaserProps | null;
}

const AnonymousHomepage: React.FC<IAnonymousHomepage> = ({ globalProps, registrationBoxProps, jobOffersTeaserProps }) => {
    return (
        <DefaultPage
            className="anonymous-homepage"
            globalProps={globalProps}
            pageContext={{
                utmSource: EPageUtmSource.ANONYMOUS_HOMEPAGE
            }}
            withSun
        >
            <article className="anonymous-homepage__content">
                <section className="anonymous-homepage__welcome">
                    <div className="anonymous-homepage__welcome-content">
                        <AnonymousIntro/>
                        <div className="anonymous-homepage__registration-box">
                            <RegistrationBox {...registrationBoxProps} />
                        </div>
                    </div>
                </section>
                { jobOffersTeaserProps && <JobOffersTeaser {...jobOffersTeaserProps} /> }
                <EcommerceTeaser />
            </article>
        </DefaultPage>
    );
};

export { AnonymousHomepage, IAnonymousHomepage };
