import './JobOffersTeaserHeader.scss';
import * as React from 'react';
import { HeadLineXL } from '../../../ui/HeadLines/HeadLineXL/HeadLineXL';
import { useTranslation } from 'react-i18next';

interface IJobOffersTeaserHeader {
    listType: string
}

enum JobOffersListType {
    SUGGESTED = 'suggested',
    NEWEST = 'newest'
}

const JobOffersTeaserHeader: React.FC<IJobOffersTeaserHeader> = ( { listType } ) => {
    const { t } = useTranslation();

    const isListSuggested = listType === JobOffersListType.SUGGESTED;
    const desktopHeadlineNewest = t('jobOffers:headlineP2:newestP1') + ' ' + t('jobOffers:headlineP2:newestP2');
    const desktopHeadlineSuggested = t('jobOffers:headlineP2:suggestedP1') + ' ' + t('jobOffers:headlineP2:suggestedP2');
    const desktopHeadlineP2 = isListSuggested ? desktopHeadlineSuggested : desktopHeadlineNewest ;

    return (
        <header className="job-offers-teaser-header">
            <span className="job-offers-teaser-header__content job-offers-teaser-header__content--mobile">
                <HeadLineXL>{ t('jobOffers:headlineP1') }</HeadLineXL>
                { isListSuggested ? (
                    <>
                        <HeadLineXL>{ t('jobOffers:headlineP2:suggestedP1') }</HeadLineXL>
                        <HeadLineXL>{ t('jobOffers:headlineP2:suggestedP2') }</HeadLineXL>
                    </>
                ) : (
                    <>
                        <HeadLineXL>{ t('jobOffers:headlineP2:newestP1') }</HeadLineXL>
                        <HeadLineXL>{ t('jobOffers:headlineP2:newestP2') }</HeadLineXL>
                    </>
                )}
            </span>
            <span className="job-offers-teaser-header__content job-offers-teaser-header__content--desktop">
                <HeadLineXL>{ t('jobOffers:headlineP1') + ' ' + desktopHeadlineP2 }</HeadLineXL>
            </span>
        </header>
    );
};

export { JobOffersTeaserHeader };
