import './MobileMenu.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MenuItemsFinder } from '../../../../class/MenuItemsFinder';
import { SocialBox } from '../../../ui/SocialBox/SocialBox';
import { IconClear } from '../../../../../assets/icons/IconClear';
import { TUserData } from '../../Header/Header';
import { useEffect, useRef } from 'react';
import { Separator } from '../../../ui/Separator/Separator';
import { hideMobileMenu } from '../../../../store/actions/mobileMenuActions';
import { IGoldenAppState } from '../../../../store/rootReducer';
import { Overlay } from '../../../ui/Overlay/Overlay';
import { SpecialActionsFinder } from "../../../../class/SpecialActionsFinder";

interface IMobileMenuProps {
    userData: TUserData | null;
}

const MobileMenu: React.FC<IMobileMenuProps> = ({ userData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const hasHrs = userData ? userData.isHrSearchActive : null;
    const menuItems = new MenuItemsFinder(t, userData, hasHrs);
    const specialActionsFinder = new SpecialActionsFinder(t);
    
    let mainMenuItems = menuItems.getMainMenuItems();

    const specialActions = specialActionsFinder.getSpecialActionsMenuItems();

    mainMenuItems = mainMenuItems.concat(specialActions);

    const topMenuItems = menuItems.getTopMenuItems(true);
    const logOutItem = menuItems.dynamicMenuItems.logOut;
    const loggedIn = Boolean(userData);
    const visible = useSelector<IGoldenAppState, any>(state => state.mobileMenuVisible);

    const mountRender = useRef(true);
    const classList = useRef({
        block: 'mobile-menu',
        overlay: 'mobile-menu__overlay'
    });

    useEffect(() => {
        mountRender.current = false;
    }, [ visible ]);

    if (!mountRender.current) {
        classList.current = {
            block: [
                'mobile-menu',
                visible ? 'mobile-menu--visible' : 'mobile-menu--hidden'
            ].join(' '),
            overlay: [
                'mobile-menu__overlay',
                visible ? 'mobile-menu__overlay--visible' : 'mobile-menu__overlay--hidden'
            ].join(' ')
        };
    }

    const onMenuClose = (): void => {
        dispatch(hideMobileMenu());
    };

    return (
        <>
            <div className={classList.current.block}>
                <div className="mobile-menu__wrap">
                    <button
                        type="button"
                        className="mobile-menu__close-btn"
                        onClick={onMenuClose}
                    >
                        <IconClear />
                    </button>
                    <div className="mobile-menu__content">
                        <nav className="mobile-menu__nav">
                            <ul className="mobile-menu__list">
                                {mainMenuItems.map((menuItem) => (
                                    <li
                                        className="mobile-menu__list-item"
                                        key={menuItem.label.toLowerCase()}
                                    >
                                        <a
                                            className="mobile-menu__link"
                                            href={menuItem.uri}
                                        >
                                            {menuItem.label}
                                        </a>
                                    </li>
                                ))}
                                {userData && (
                                    <li
                                        className="mobile-menu__list-item"
                                    >
                                        <a
                                            className="mobile-menu__link"
                                            href={`/${ userData.userProfileUrlname }`}
                                        >
                                            {t('header:nav:myProfile')}
                                        </a>
                                    </li>
                                )}
                            </ul>
                            <Separator className="mobile-menu__separator" />
                            {loggedIn && (
                                <ul className="mobile-menu__list">
                                    {
                                        topMenuItems.map((menuItem) => (
                                            <li
                                                className="mobile-menu__list-item"
                                                key={menuItem.label.toLowerCase()}
                                            >
                                                <a
                                                    className="mobile-menu__link"
                                                    href={menuItem.uri}
                                                >
                                                    {menuItem.label}
                                                </a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            )}
                        </nav>
                        <div className="mobile-menu__footer">
                            <SocialBox
                                size={40}
                                className="mobile-menu__social-box"
                            />
                            {loggedIn && (
                                <a
                                    className="mobile-menu__link"
                                    href={logOutItem.uri}
                                >
                                    {logOutItem.label}
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Overlay className={classList.current.overlay} />
        </>
    );
};

export { MobileMenu };
