import './TextField.scss';
import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { IconLoading } from '../../../../../assets/icons/IconLoading';
import { getClassList } from '../../../../utils/getClassList';

interface ITextInput {
    name: string;
    value: string | number;
    actions: TTextFieldActions;
    type: TextFieldType;
    label?: string;
    errorMsg?: string;
    disabled?: boolean;
    readOnly?: boolean;
    placeholder?: string | number;
    domRef?: any;
    disableBrowserAutocomplete?: boolean;
    isLoading?: boolean;
}

enum TextFieldType {
    TEXT = 'text',
    EMAIL = 'email',
    PASSWORD = 'password'
}

type TTextFieldActions = {
    onChange?: (newValue: string) => void,
    onChangeEvent?: (event: React.ChangeEvent) => void,
    onFocus?: () => void,
    onBlur?: () => void,
    onBlurEvent?: (event: React.FocusEvent) => void,
    onKeyDown?: (event: React.KeyboardEvent) => void,
}

const TextField: React.FC<ITextInput> = ({ name, value, actions, type, label, errorMsg, disabled, readOnly, placeholder, domRef, disableBrowserAutocomplete, isLoading }) => {
    const defaultInputStatus = disabled ? 'disabled' : 'idle';
    const [ inputStatus, setInputStatus ] = useState(defaultInputStatus);

    const classList = getClassList([
        'text-field',
        inputStatus === 'focus' ? 'text-field--focus' : '',
        disabled ? 'text-field--disabled' : '',
        readOnly ? 'text-field--readonly' : '',
        errorMsg ? 'text-field--error' : ''
    ]);

    const handlers = {
        onFocus: (): void => {
            setInputStatus('focus');
            actions.onFocus && actions.onFocus();
        },
        onChange: (event: ChangeEvent<HTMLInputElement>): void => {
            actions.onChange && actions.onChange(event.target.value);
            actions.onChangeEvent && actions.onChangeEvent(event);
        },
        onBlur: (event: React.FocusEvent): void => {
            actions.onBlur && actions.onBlur();
            actions.onBlurEvent && actions.onBlurEvent(event);
        },
        onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>): void => {
            actions.onKeyDown && actions.onKeyDown(event)
        }
    };

    return (
        <div className={classList}>
            {label && (
                <label
                    className="text-field__label"
                    htmlFor={name}
                >
                    {label}
                </label>
            )}
            <div className="text-field__input-wrapper">
                <input
                    name={name}
                    id={name}
                    className="text-field__input"
                    type={type}
                    value={value}
                    onChange={handlers.onChange}
                    onFocus={handlers.onFocus}
                    onBlur={handlers.onBlur}
                    onKeyDown={handlers.onKeyDown}
                    disabled={disabled}
                    readOnly={readOnly}
                    placeholder={`${ placeholder ? placeholder : '' }`}
                    ref={domRef ? domRef : null}
                    autoComplete={disableBrowserAutocomplete ? 'off' : 'on'}
                />
                {isLoading && <div className="text-field__loader">
                    <IconLoading />
                </div>}
            </div>
            {errorMsg && (
                <span className="text-field__error-msg">
                    {errorMsg}
                </span>
            )}
        </div>
    );
};

export { TextField, ITextInput, TTextFieldActions, TextFieldType };
