import './NavItem.scss';
import * as React from 'react';

interface INavItem {
    url: string;
    children: string;
    target?: string;
}

const NavItem: React.FC<INavItem> = ({ children, url, target }) => {
    return (
        <a
            className="nav-item"
            href={url}
            target={target}
        >
            {children}
        </a>
    );
};

export { NavItem, INavItem };
