import './SocialBox.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IconFacebook } from '../../../../assets/icons/IconFacebook';

interface ISocialBoxProps {
    /**
     * Rozmiar podany jako cyfra, będzie skutowac rozmiarem ikon i odstępem między nimi
     */
    size: number;
    className?: string;
}

const SocialBox: React.FC<ISocialBoxProps> = ({ size, className }) => {
    const { t } = useTranslation();
    const classList = [
        'social-box',
        className ? className : '',
    ].join(' ');

    return (
        <aside
            className={classList}
            style={{
                fontSize: `${ size }px`,
            }}
        >
            <a
                href={t('paths:url:facebook')}
                className="social-box__media-icon"
            >
                <IconFacebook />
            </a>
        </aside>
    );
};

export { SocialBox };
