import { AlgoliaQuery, IAlgoliaQuery, TQueryData } from './AlgoliaQuery';

/**
 * Firms - Possible fields
 *
 * @string logo
 * @long objectID  - Nie używać w indexFields, zawsze jest zwracane domyślnie w results
 * @string name
 * @long counter
 * @string type
 * @string _tags
 * @string urlname
 * @string url
 */

type TFirmData = {
    objectID: number,
    name: string,
    logo: string,
    url: string
}

function getFirmQueries(query: string, userFilter: string): { 0: IAlgoliaQuery, 1: IAlgoliaQuery } {
    const algoliaQueryParams: TQueryData = {
        indexName: 'firms',
        indexFields: 'name,logo,url',
        userFilter: userFilter,
        query: query,
    };

    const privateFirmQuery: IAlgoliaQuery = new AlgoliaQuery(algoliaQueryParams, false);
    const publicFirmQuery: IAlgoliaQuery = new AlgoliaQuery(algoliaQueryParams, true);

    return [
        privateFirmQuery,
        publicFirmQuery
    ];
}

export { getFirmQueries, TFirmData };
