import './Thumbnail.scss';
import * as React from 'react';
import { IconCompany } from '../../../../assets/icons/IconCompany';

enum sizeType {
    BIG,
    SMALL
}

interface IThumbnail {
    size: sizeType,
    className?: string,
    imgData?: {
        url: string,
        name: string
    }
}

const Thumbnail: React.FC<IThumbnail> = ({ size, className, imgData }) => {
    const classList = [
        'thumbnail',
        className ? className : '',
        size === sizeType.BIG ? 'thumbnail--big' : '',
        size === sizeType.SMALL ? 'thumbnail--small' : '',
        !imgData ? 'thumbnail--none' : ''
    ].join(' ');

    return (
        <figure className={classList}>
            {
                imgData && (
                    <img
                        className="thumbnail__image"
                        src={imgData.url}
                        alt={imgData.name}
                    />
                )
            }
            {
                !imgData && (
                    <IconCompany />
                )
            }
        </figure>
    );
};

export { Thumbnail, sizeType };
