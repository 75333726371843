import * as React from 'react';
import {SvgIcon} from './SvgIcon';

const IconSearch = () => {
    return (
        <SvgIcon
            name="search"
            svgXml={`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1332 1.75708H12.6418C9.84371 1.75708 7.55322 3.96032 7.55322 6.64812V9.04367C7.55322 11.736 9.84371 13.9362 12.6418 13.9362H15.1332C17.9344 13.9362 20.2217 11.736 20.2217 9.04367V6.64812C20.2217 3.96032 17.9344 1.75708 15.1332 1.75708Z" stroke="#878787" stroke-miterlimit="2.6131"/>
                    <path d="M10.1239 13.5093L4.34863 23.2429" stroke="#878787" stroke-miterlimit="2.6131"/>
                    <path d="M8.50296 16.2378L4.34863 23.2427" stroke="#878787" stroke-width="2" stroke-miterlimit="2.6131"/>
                </svg>
            `}
        />
    );
};

export { IconSearch }
