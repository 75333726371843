import './HeadLineL.scss';
import * as React from 'react';

const HeadLineL: React.FC = ({ children }) => {
    return (
        <h3 className="head-line-l">
            {children}
        </h3>
    );
};

export { HeadLineL }
