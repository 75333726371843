import * as React from 'react';
import {SvgIcon} from './SvgIcon';

const EcommerceJobAds = () => {
    return (
        <SvgIcon
            name="EcommerceJobAds"
            svgXml={`
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 400 400">
                    <g fill="none" fill-rule="evenodd">
                        <circle cx="160.001" cy="160.033" r="160" fill="#EDF7FD" transform="rotate(-90 200.001 160.033)"/>
                        <g transform="translate(70 79)">
                            <ellipse cx="109.651" cy="204.441" fill="#CACACA" rx="80.427" ry="8.616"/>
                            <path fill="#EDF7FD" d="M36.667 18.387A18.26 18.26 0 0 0 31.34 5.443 18.158 18.158 0 0 0 18.433.102h133.929a18.158 18.158 0 0 1 12.908 5.34 18.261 18.261 0 0 1 5.326 12.945v182.998H54.95c-10.078 0-18.256-8.179-18.283-18.285V18.387z"/>
                            <path fill="#FFF" fill-rule="nonzero" d="M152.362.102a18.158 18.158 0 0 1 12.908 5.34 18.261 18.261 0 0 1 5.326 12.945v3.056H36.666v-3.056a18.26 18.26 0 0 0-5.325-12.944A18.158 18.158 0 0 0 18.433.102h133.929z"/>
                            <path stroke="#434343" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M36.667 18.387A18.26 18.26 0 0 0 31.34 5.443 18.158 18.158 0 0 0 18.433.102h133.929a18.158 18.158 0 0 1 12.908 5.34 18.261 18.261 0 0 1 5.326 12.945v182.998H54.95c-10.078 0-18.256-8.179-18.283-18.285V18.387z"/>
                            <path fill="#C2E3F7" fill-rule="nonzero" stroke="#434343" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M.2 37.022c0 3.182 2.572 5.761 5.745 5.761h30.722V18.387A18.294 18.294 0 0 0 27.55 2.552a18.19 18.19 0 0 0-18.233 0A18.294 18.294 0 0 0 .2 18.387v18.635z"/>
                            <path fill="#FFF" fill-rule="nonzero" stroke="#434343" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M170.496 201.385c9.713-.51 17.328-8.556 17.328-18.31s-7.615-17.8-17.328-18.31H54.95c9.713.51 17.328 8.556 17.328 18.31s-7.615 17.8-17.328 18.31h115.546z"/>
                            <rect width="72.984" height="72.037" x="66.29" y="32.013" fill="#5AB4E6" rx="1.25" transform="rotate(180 102.782 68.031)"/>
                            <path stroke="#434343" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M125.736 123.186H66.24M117.094 141.47H66.24"/>
                            <ellipse cx="204.115" cy="253.083" fill="#CACACA" rx="45.958" ry="8.616"/>
                            <path fill="#FFC530" d="M275.95 141.37a9.28 9.28 0 0 0-2.724-6.57 9.228 9.228 0 0 0-6.568-2.697h-37.066a4.653 4.653 0 0 1-4.596-4.659v-37.17a9.28 9.28 0 0 0-2.724-6.571 9.229 9.229 0 0 0-6.567-2.697h-23.38c-5.103 0-9.24 4.15-9.24 9.268v37.17a4.652 4.652 0 0 1-4.647 4.659h-36.866c-5.085.028-9.192 4.169-9.192 9.268v23.244c0 5.126 4.13 9.29 9.242 9.318h37.066a4.652 4.652 0 0 1 4.646 4.608v37.171c-.03 5.042 3.966 9.182 8.992 9.318h23.179c5.131 0 9.291-4.172 9.291-9.318v-37.17a4.602 4.602 0 0 1 4.596-4.61h37.067c5.131 0 9.291-4.171 9.291-9.317l.2-23.244z"/>
                            <path fill="#FFD758" d="M215.705 81.006h-23.38c-5.103 0-9.24 4.15-9.24 9.268v37.17a4.652 4.652 0 0 1-4.647 4.659h-36.866c-5.085.028-9.192 4.169-9.192 9.268v23.244c0 5.126 4.13 9.29 9.242 9.318h20.73l62.644-63.07v-20.59a9.28 9.28 0 0 0-2.724-6.57 9.229 9.229 0 0 0-6.567-2.697z"/>
                            <path stroke="#434343" stroke-linejoin="round" stroke-width="3" d="M275.95 141.37a9.28 9.28 0 0 0-2.724-6.57 9.228 9.228 0 0 0-6.568-2.697h-37.066a4.653 4.653 0 0 1-4.596-4.659v-37.17a9.28 9.28 0 0 0-2.724-6.571 9.229 9.229 0 0 0-6.567-2.697h-23.38c-5.103 0-9.24 4.15-9.24 9.268v37.17a4.652 4.652 0 0 1-4.647 4.659h-36.866c-5.085.028-9.192 4.169-9.192 9.268v23.244c0 5.126 4.13 9.29 9.242 9.318h37.066a4.652 4.652 0 0 1 4.646 4.608v37.171c-.03 5.042 3.966 9.182 8.992 9.318h23.179c5.131 0 9.291-4.172 9.291-9.318v-37.17a4.602 4.602 0 0 1 4.596-4.61h37.067c5.131 0 9.291-4.171 9.291-9.317l.2-23.244z"/>
                            <path fill="#64C8FF" d="M66.24 78.15l46.158-46.137H71.985c-3.162.027-5.718 2.59-5.745 5.76V78.15z"/>
                            <path fill="#E8F4FA" fill-rule="nonzero" d="M77.73 104.2v-1.453c-.418-9.533 4.415-18.528 12.586-23.42a25.814 25.814 0 0 1 26.53 0c8.171 4.892 13.005 13.887 12.587 23.42v1.453H77.73z"/>
                            <path fill="#E8F4FA" fill-rule="nonzero" d="M92.117 65.126c0 6.363 5.144 11.522 11.49 11.522 6.345 0 11.489-5.159 11.489-11.522v-5.761c0-6.364-5.144-11.522-11.49-11.522-6.345 0-11.49 5.158-11.49 11.522v5.76z"/>
                            <path fill="#FFF" fill-rule="nonzero" d="M103.606 47.843c6.346 0 11.49 5.158 11.49 11.522v5.76c.027.485.027.97 0 1.453-.757-5.7-5.605-9.958-11.34-9.958-5.735 0-10.583 4.258-11.34 9.958a6.832 6.832 0 0 1 0-1.452v-5.761c-.002-6.248 4.962-11.36 11.19-11.522z"/>
                            <path fill="#FFF" fill-rule="nonzero" stroke="#434343" stroke-width="3" d="M92.117 64.126c0 6.363 5.144 11.522 11.49 11.522 6.345 0 11.489-5.159 11.489-11.522v-5.761c0-6.364-5.144-11.522-11.49-11.522-6.345 0-11.49 5.158-11.49 11.522v5.76z"/>
                            <path stroke="#434343" stroke-width="3" d="M113.048 51.702a19.147 19.147 0 0 1-20.931 5.26"/>
                            <path fill="#FFF" fill-rule="nonzero" stroke="#434343" stroke-linejoin="round" stroke-width="3" d="M77.73 104.2v-1.453c-.418-9.533 4.415-18.528 12.586-23.42a25.814 25.814 0 0 1 26.53 0c8.171 4.892 13.005 13.887 12.587 23.42v1.453H77.73z"/>
                            <rect width="72.984" height="72.037" x="66.29" y="32.013" stroke="#434343" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" rx="1.25" transform="rotate(180 102.782 68.031)"/>
                        </g>
                    </g>
                </svg>
            `}
        />
    );
};

export { EcommerceJobAds };
