import * as React from 'react';
import { SvgIcon } from './SvgIcon';

const IconMessage = () => {
    return (
        <SvgIcon
            name="message"
            svgXml={`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54802 4H17.9678C19.9197 4 21.5148 5.57467 21.5148 7.5003V17.1268C21.5148 19.0541 19.9197 20.6279 17.9678 20.6279H5.54802C3.59774 20.6279 2.00098 19.0533 2.00098 17.1268V7.5003C2.00098 5.57467 3.59774 4 5.54802 4Z" stroke="#878787" stroke-miterlimit="2.6131"/>
                    <path d="M21.5147 9.73096L14.6628 16.4919C13.0643 18.0683 10.4504 18.0683 8.85189 16.4919L2 9.73096" stroke="#878787" stroke-miterlimit="2.6131"/>
                </svg>
            `}
        />
    );
};

export { IconMessage }
