import './AnonymousIntro.scss';
import * as React from 'react';
import { getLocale } from '../../../utils/getLocale';
import { HeadLineXL } from '../../ui/HeadLines/HeadLineXL/HeadLineXL';
import { HeadLineXXL } from '../../ui/HeadLines/HeadLineXXL/HeadLineXXL';
import { LinkButton } from '../../ui/Buttons/LinkButton/LinkButton';
import { useTranslation } from 'react-i18next';

const AnonymousIntro: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="anonymous-intro">
            <LinkButton
                className="anonymous-intro__shop-link anonymous-intro__shop-link--top"
                url={t('paths:url:businessOffer')}
                small
            >
                {t('anonymousIntro:seeShopOffers')}
            </LinkButton>
            <span className="anonymous-intro__headline">
                {getLocale() === 'uk' ? (
                    <HeadLineXL>{t('anonymousIntro:letsTakeCare')}</HeadLineXL>
                ) : (
                    <HeadLineXXL>{t('anonymousIntro:letsTakeCare')}</HeadLineXXL>
                )}
                {getLocale() === 'uk' ? (
                    <HeadLineXL>{t('anonymousIntro:ofYourJob')}</HeadLineXL>
                ) : (
                    <HeadLineXXL>{t('anonymousIntro:ofYourJob')}</HeadLineXXL>
                )}
            </span>
            <span className="anonymous-intro__description">
                {t('anonymousIntro:introP1')}
                <br /><br />
                {t('anonymousIntro:introP2')}
            </span>
            <LinkButton
                className="anonymous-intro__shop-link"
                url={t('paths:url:shop:adOffers')}
                small
            >
                {t('anonymousIntro:seeJobOffersLanding')}
            </LinkButton>
            <LinkButton
                className="anonymous-intro__shop-link"
                url={t('paths:url:shop:searchOffers')}
                small
            >
                {t('anonymousIntro:seeHrsLanding')}
            </LinkButton>
        </div>
    );
};

export { AnonymousIntro };
