import * as React from 'react';
import { Context } from 'react';

interface IPageContext {
    utmSource: string;
}

const defaultPageContext: IPageContext = {
    utmSource: 'HP'
};

const PageContext: Context<IPageContext> = React.createContext(defaultPageContext);

export { PageContext, IPageContext };
