import './Separator.scss';
import * as React from 'react';

interface ITextSeparator {
    className?: string
}

const Separator: React.FC<ITextSeparator> = ({ children, className }) => {
    const classList = [
        'separator',
        className ? className : ''
    ].join(' ');

    return (
        <div className={classList}>
            <div className="separator__line separator__line--left" />
            {children && (
                <span className="separator__text">{children}</span>
            )}
            <div className="separator__line separator__line--right" />
        </div>
    );
};

export { Separator }
