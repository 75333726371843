import './LoadingOverlay.scss';
import * as React from 'react';
import { IconLoading } from '../../../../assets/icons/IconLoading';

interface ILoadingOverlay {
    /**
     * Overlay powinien być wywołany w rodzicu który dostarczy box dla position absolute.
     * className jest obowiązkowe, ponieważ musimy dostarczyć z-index
     */
    className: string
}

const LoadingOverlay: React.FC<ILoadingOverlay> = ({ className }) => {
    return (
        <div className={`loading-overlay ${ className } `}>
            <div className="loading-overlay__icon">
                <IconLoading />
            </div>
        </div>
    );
};

export { LoadingOverlay };
