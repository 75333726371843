import * as React from 'react';
import { SvgIcon } from './SvgIcon';

const IconArrow = () => {
    return (
        <SvgIcon
            name="arrow"
            svgXml={`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="icon/arrow">
                <path id="icon/action/trending_flat_24px" d="M2.5 12L6.5 16L6.5 13L21.5 13L21.5 11L6.5 11L6.5 8L2.5 12Z" fill="#6E768B"/>
                </g>
                </svg>
            `}
        />
    );
};

export { IconArrow }
