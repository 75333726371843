import * as React from 'react';
import {SvgIcon} from './SvgIcon';

const IconAlert = () => {
    return (
        <SvgIcon
            name="alert"
            svgXml={`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.3032 19.4895C15.3032 21.0893 14.0074 22.386 12.4084 22.386C10.8095 22.386 9.51367 21.0893 9.51367 19.4895" stroke="#878787" stroke-miterlimit="2.6131"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4082 6.27849C13.3117 6.27849 14.0496 5.54224 14.0496 4.64055C14.0496 3.73712 13.3117 3 12.4082 3C11.5065 3 10.7686 3.73712 10.7686 4.64055C10.7686 5.54311 11.5065 6.27849 12.4082 6.27849Z" stroke="#878787" stroke-miterlimit="2.6131"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2989 9.71539C17.2175 6.99039 15.9745 4.9436 13.0797 4.9436H12.4266H11.7683C8.87356 4.9436 7.63232 6.99039 7.55263 9.71539C7.44783 13.3508 7.98919 14.1476 5 17.006V18.0359H12.4266H19.8498V17.006C16.8615 14.1476 17.4037 13.3508 17.2989 9.71539Z" fill="white" stroke="#878787" stroke-miterlimit="2.6131"/>
                </svg>
            `}
        />
    );
};

export { IconAlert };
