import './HeadLineXL.scss';
import * as React from 'react';

const HeadLineXL: React.FC = ({ children }) => {
    return (
        <h2 className="head-line-xl">
            <span className="head-line-xl__text">
                {children}
            </span>
            <div className="head-line-xl__underline" />
        </h2>
    );
};

export { HeadLineXL }
