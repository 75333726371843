/**
 * Tutaj składamy domyślny stan i reducery, które potem będą wykorzystane przy tworzeniu reduxowego store
 * Na każdy "kawałek" stanu tworzymy reducer z typem i domyślnym stanem w ./reducers
 * Na każdy "kawałek" stanu tworzymy też akcje z typem w ./actions
 *
 * Jeśli ma to sens to oczywiście powiększamy powyższe o foldery zamiast pliki.
 */

import { combineReducers } from 'redux';
import { mobileMenuInitialState, mobileMenuReducer } from './reducers/mobileMenuReducer';
import { ITopCardsState, topCardsInitialState, topCardsReducer } from './reducers/topCardsReducer';
import { ILiveSearchState, liveSearchInitialState, liveSearchReducer } from './reducers/liveSearchReducer';

interface IGoldenAppState {
    topCards: ITopCardsState;
    mobileMenuVisible: boolean;
    liveSearch: ILiveSearchState
}

const initialState: IGoldenAppState = {
    topCards: topCardsInitialState,
    mobileMenuVisible: mobileMenuInitialState,
    liveSearch: liveSearchInitialState
};

const rootReducer = combineReducers({
    mobileMenuVisible: mobileMenuReducer,
    topCards: topCardsReducer,
    liveSearch: liveSearchReducer
});

export { rootReducer, initialState, IGoldenAppState };
