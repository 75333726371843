import { Store, applyMiddleware, createStore, StoreEnhancer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { loggerMiddleware } from './middleware/loggerMiddleware';
import { rootReducer } from './rootReducer';
import { EnvironmentType, getEnvironment } from '../utils/environment';

function getStore(initialState: object): Store {
    const middlewares = getEnvironment() === EnvironmentType.PROD ? [] : [ loggerMiddleware ];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [ middlewareEnhancer ];
    const composedEnhancers: StoreEnhancer = composeWithDevTools(...enhancers);

    const store = createStore(rootReducer, initialState, composedEnhancers);

    return store;
}

export { getStore }
