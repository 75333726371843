import {TFunction} from 'i18next';
import {TMenuItem} from "./MenuItemsFinder";

class SpecialActionsFinder {
    private readonly trans: TFunction;

    constructor(trans: TFunction) {
        this.trans = trans;
    }

    getSpecialActionsMenuItems(): TMenuItem[] {
        return [
            {
                label: this.trans('header:nav:specialActionJobsUkraine'),
                uri: this.trans('paths:uri:specialActionJobsUkraine'),
            },
        ];
    }
}

export { SpecialActionsFinder };
