import './AmountBox.scss';
import * as React from 'react';

interface IAmountBoxProps {
    amount: number
}

const AmountBox: React.FC<IAmountBoxProps> = ({ children, amount }) => {
    return (
        <div className="amount-box">
            { amount != 0 &&
                <div className="amount-box__number">
                    {amount}
                </div>
            }

            <div className="amount-box__content">
                {children}
            </div>
        </div>
    );
};

export { AmountBox };
