import './TeaserSection.scss';
import * as React from 'react';
import { HeadLineXL } from '../../ui/HeadLines/HeadLineXL/HeadLineXL';
import { DefaultButton, IDefaultButtonProps } from '../../ui/Buttons/DefaultButton/DefaultButton';

interface ITeaserSection {
    headlineText?: string,
    buttonData?: IDefaultButtonProps & { children: string },
    className?: string
}

const TeaserSection: React.FC<ITeaserSection> = ({ children, headlineText, buttonData, className }) => {
    const classList = [
        'teaser-section',
        className ? className : ''
    ].join(' ');

    return (
        <div className={classList}>
            <div className="teaser-section__wrapper">
                {headlineText && (
                    <div className="teaser-section__header">
                        <HeadLineXL>{ headlineText }</HeadLineXL>
                    </div>
                )}

                <div className="teaser-section__body">
                    { children }
                </div>

                {buttonData && (
                    <div className="teaser-section__button">
                        <DefaultButton {...buttonData} />
                    </div>
                )}
            </div>
        </div>
    )
};

export { TeaserSection }
