import { EReduxActionTypes, IReduxBaseAction } from '../actions';

interface IMobileMenuAction extends IReduxBaseAction {
    type: EReduxActionTypes.SHOW_MOBILE_MENU | EReduxActionTypes.HIDE_MOBILE_MENU
}

function showMobileMenu(): IMobileMenuAction {
    return {
        type: EReduxActionTypes.SHOW_MOBILE_MENU
    }
}

function hideMobileMenu(): IMobileMenuAction {
    return {
        type: EReduxActionTypes.HIDE_MOBILE_MENU
    }
}

export { showMobileMenu, hideMobileMenu, IMobileMenuAction };
