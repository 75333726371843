import './SocialButton.scss';
import * as React from 'react';
import {IconFacebookShield} from '../../../../../assets/icons/IconFacebookShield';
import {IconGoogleShield} from '../../../../../assets/icons/IconGoogleShield';

interface ILinkButtonProps {
    socialMedia: SocialMedia
    onClick?: () => void,
    url?: string
}

enum SocialMedia {
    FACEBOOK,
    GOOGLE
}

const SocialButton: React.FC<ILinkButtonProps> = ({ children, socialMedia, onClick, url }) => {
    const classList = [
        'social-button',
        socialMedia === SocialMedia.FACEBOOK ? 'social-button--facebook' : 'social-button--google'
    ].join(' ');

    if (url) {
        return (
            <a
                href={url}
                className={classList}
                onClick={onClick ? onClick : undefined}
                rel="nofollow"
            >
                <div className="social-button__icon">
                    {socialMedia === SocialMedia.FACEBOOK && <IconFacebookShield/>}
                    {socialMedia === SocialMedia.GOOGLE && <IconGoogleShield/>}
                </div>
                <span className="social-button__text">
                    {children}
                </span>
            </a>
        )
    }

    return (
        <button
            type="button"
            className={classList}
            onClick={onClick ? onClick : undefined}
        >
            <figure className="social-button__icon">
                {socialMedia === SocialMedia.FACEBOOK && <IconFacebookShield/>}
                {socialMedia === SocialMedia.GOOGLE && <IconGoogleShield/>}
            </figure>
            <span className="social-button__text">
                {children}
            </span>
        </button>
    );
};

export { SocialButton, SocialMedia };
