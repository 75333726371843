import './NavIcon.scss';
import * as React from 'react';
import { getClassList } from '../../../../utils/getClassList';

interface INavIcon {
    className?: string,
    url?: string,
    onClick?: () => void,
    disabled?: boolean
}

const NavIcon: React.FC<INavIcon> = ({ children, className, url, onClick, disabled }) => {
    const classList = getClassList([
        'nav-icon',
        disabled && 'nav-icon--disabled',
        className && className
    ]);

    if (url) {
        return (
            <a
                href={url}
                className={classList}
                onClick={onClick}
            >
                {children}
            </a>
        );
    }

    return (
        <button
            type="button"
            className={classList}
            disabled={disabled}
            onClick={onClick}
        >
            <div className="nav-icon__svg">
                {children}
            </div>
        </button>
    )
};

export { NavIcon, INavIcon };
