enum EPageUtmSource {
    USER_HOMEPAGE = 'UserHP',
    ANONYMOUS_HOMEPAGE = 'HP',
    REGISTRATION_PAGE = 'RegistrationPage',
    LOGIN_PAGE = 'LoginPage',
    WEB_SURVEY_RESULT_PAGE = 'WebSurveyResultPage',
    PROFILE_VISITORS_PAGE = 'ProfileVisitorsPage',
    CONTACT_PAGE = 'ContactPage'
}

export { EPageUtmSource }
