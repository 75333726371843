import './DefaultPage.scss';
import * as React from 'react';
import { GoldenApp, IGoldenApp } from '../../GoldenApp';
import { Header, IHeaderProps, TInboxData, TNotificationsData } from '../../bl/Header/Header';
import { ILiveSearch, LiveSearch } from '../../bl/topCards/LiveSearch/LiveSearch';
import { IPageContext, PageContext } from '../../context/PageContext';
import { Footer } from '../../bl/Footer/Footer';
import { MobileMenu } from '../../bl/topCards/MobileMenu/MobileMenu';
import { Notifications } from '../../bl/topCards/Notifications/Notifications';
import { TheSun } from '../../ui/TheSun/TheSun';

interface IDefaultPage {
    globalProps: IGlobalProps;
    className: string;
    pageContext: IPageContext;
    withSun?: boolean;
}

interface IGlobalProps {
    lang: string;
    profilingConsent: TGlobalProfilingConsent;
    headerProps: TGlobalHeaderProps | null;
    userProps: TGlobalUserProps | null;
    liveSearchProps: TGlobalLiveSearchProps | null;
    environment: string;
}

type TGlobalUserProps = {
    userProfileUrlname: string;
    userProfilePhotoUrl: string | null;
    isHrSearchActive: boolean;
}

type TGlobalProfilingConsent = {
    isAgreedForProfiling: boolean;
    csrfToken: string
}

type TGlobalHeaderProps = {
    inboxData: TInboxData | null;
    notificationsData: TNotificationsData | null;
}

type TGlobalLiveSearchProps = {
    publicKey: string;
    tagFilters: string;
}

interface IDefaultPageRenderProps {
    goldenApp: IGoldenApp;
    header: IHeaderProps;
    liveSearch: ILiveSearch | null;
}

const DefaultPage: React.FC<IDefaultPage> = ({ children, globalProps, className, pageContext, withSun }) => {
    const { headerProps, userProps, liveSearchProps, environment } = globalProps;
    const notificationsList = headerProps && headerProps.notificationsData ? headerProps.notificationsData.notificationsList : [];

    const checks = {
        loggedIn: Boolean(userProps),
        notificationsAvailable: Boolean(headerProps && headerProps.notificationsData)
    };

    const renderProps: IDefaultPageRenderProps = {
        goldenApp: {
            environment,
            lang: globalProps.lang,
            loggedIn: checks.loggedIn,
        },
        header: headerProps ? {
            inboxData: headerProps.inboxData ? headerProps.inboxData : null,
            notificationsData: headerProps.notificationsData ? headerProps.notificationsData : null,
            userData: userProps,
        } : {
            inboxData: null,
            notificationsData: null,
            userData: null,
        },
        liveSearch: liveSearchProps ? {
            apiKey: liveSearchProps.publicKey,
            hasHrs: userProps ? userProps.isHrSearchActive : false,
            tagFilters: liveSearchProps.tagFilters,
        } : null
    };

    return (
        <GoldenApp {...renderProps.goldenApp}>
            <PageContext.Provider value={pageContext}>
                { withSun ?
                    <TheSun parallax />
                    :
                    <div className="default-page__background" />
                }
                <div className={`default-page ${ className }`}>
                    <Header {...renderProps.header} />
                    <div className="default-page__top-cards">
                        <div className="default-page__top-cards-content">
                            {checks.loggedIn && checks.notificationsAvailable && (
                                <Notifications
                                    notificationsList={notificationsList}
                                />
                            )}
                            {checks.loggedIn && renderProps.liveSearch &&
                            <LiveSearch {...renderProps.liveSearch} />
                            }
                        </div>
                    </div>
                    <main className="default-page__main">
                        {children}
                    </main>
                    <Footer />
                </div>
                <MobileMenu userData={userProps} />
            </PageContext.Provider>
        </GoldenApp>
    );
};

export { DefaultPage,
    IDefaultPage, IGlobalProps, TGlobalHeaderProps, TGlobalLiveSearchProps, TGlobalProfilingConsent, TGlobalUserProps };
