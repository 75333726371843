import './CareerCenterTeaser.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '../../../../ui/Link/Link';
import { useDispatch } from 'react-redux';
import { hideSearch } from '../../../../../store/actions/topCardsActions';

const CareerCenterTeaser: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onLinkClick = (): void => {
        dispatch(hideSearch());
    };

    return (
        <div className="career-center-teaser">
            <span className="career-center-teaser__title">
                {t('liveSearch:ifYouLookForArticle')}
            </span>
            <Link
                href={t('paths:uri:careerCenter')}
                onClick={onLinkClick}
            >
                {t('liveSearch:goToCareerCenter')}
            </Link>
        </div>
    );
};

export { CareerCenterTeaser };
