import './Link.scss';
import * as React from 'react';

interface ILink {
    /**
     * 'title' stanie się 'name', jeśli dostarczymy inForm, czyli zamienimy <a> na <button>
     */
    title?: string;
    href?: string;
    onClick?: () => void;
    className?: string;
    small?: boolean;
    blank?: boolean;
    /**
     * Zamienia <a> na <button>
     */
    inForm?: boolean;
}

const Link: React.FC<ILink> = ({ children, title, href, onClick, className, small, blank, inForm }) => {
    const classList = [
        'link',
        className ? className : '',
        small ? small : ''
    ].join(' ');

    const blankProps = {
        target: '_blank',
        rel: 'noopener noreferrer'
    };

    if (inForm) {
        return (
            <button
                type="submit"
                className={classList}
                onClick={onClick}
                name={title}
                {...blank && blankProps}
            >
                { children }
            </button>
        );
    }

    return (
        <a
            title={title}
            href={href}
            className={classList}
            onClick={onClick}
            {...blank && blankProps}
        >
            { children }
        </a>
    );
};

export { Link };
