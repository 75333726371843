import './HeadLineXXL.scss';
import * as React from 'react';

const HeadLineXXL: React.FC = ({ children }) => {
    return (
        <h1 className="head-line-xxl">
            <span className="head-line-xxl__text">
                {children}
            </span>
            <div className="head-line-xxl__underline" />
        </h1>
    );
};

export { HeadLineXXL }
