import './GoldenApp.scss';
import * as React from 'react';
import { GlobalComponents } from './GlobalComponents';
import { GlobalContextProvider } from './context/GlobalContext';
import { Provider } from 'react-redux';
import { getStore } from '../store/getStore';
import { initialState } from '../store/rootReducer';

interface IGoldenApp {
    environment: string;
    lang: string;
    loggedIn: boolean;
}

const store = getStore(initialState);

const GoldenApp: React.FC<IGoldenApp> = ({ children, environment, lang, loggedIn }) => {
    return (
        <Provider store={store}>
            <GlobalContextProvider environment={environment} lang={lang} loggedIn={loggedIn}>
                {children}
                <GlobalComponents />
            </GlobalContextProvider>
        </Provider>
    );
};

export { GoldenApp, IGoldenApp };
