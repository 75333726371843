import { EReduxActionTypes } from '../actions';
import { TLiveSearchActions } from '../actions/liveSearchActions';

interface ILiveSearchState {
    query: string;
    isLoading: boolean;
}

const liveSearchInitialState: ILiveSearchState = {
    query: '',
    isLoading: false
};

function liveSearchReducer(
    state: ILiveSearchState = liveSearchInitialState,
    action: TLiveSearchActions
): ILiveSearchState {
    switch (action.type) {
        case (EReduxActionTypes.SET_LIVESEARCH_QUERY):
            return {
                query: action.query,
                isLoading: false
            };

        case EReduxActionTypes.SET_LIVESEARCH_ISLOADING:
            return {
                query: state.query,
                isLoading: action.isLoading
            };

        default:
            return state;
    }
}

export { liveSearchReducer, ILiveSearchState, liveSearchInitialState }
