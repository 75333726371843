import { IWindow } from '../typings/IWindow';

declare const window: IWindow & Window;

const getLocale = (): string | null => {
    if (typeof window !== 'undefined' && window.LOCALE) {
        return window.LOCALE;
    }

    return null;
};

export { getLocale };
