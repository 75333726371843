import * as React from 'react';
import { initI18n } from '../../translations/i18next';

interface ITranslatedComponent {
    lang: string;
}

function withI18n <P extends object>(PageComponent: React.FC<P>) {
    const TranslatedComponent: React.FC<P & ITranslatedComponent> = ({ lang, ...restProps }) => {
        initI18n(lang);

        return (
            <PageComponent {...restProps as P} />
        );
    };

    return TranslatedComponent;
};

export { withI18n };

