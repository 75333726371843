import * as React from 'react';
import { SvgIcon } from './SvgIcon';

const IconUser: React.FC = () => {
    return (
        <SvgIcon
            name="user"
            svgXml={`
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="24" fill="#F3F4F6"/>
                <path d="M33.473 34H14.526L14.46 32.663C14.459 29.105 16.854 25.142 19.797 23.806L20.211 24.716C17.636 25.887 15.46 29.515 15.46 32.639L15.478 33H32.522L32.541 32.613C32.54 29.542 30.384 25.924 27.833 24.734L28.255 23.828C31.169 25.186 33.54 29.139 33.54 32.639L33.473 34Z" fill="#B9BECB"/>
                <path d="M24.0379 26.152C20.1369 26.152 16.9639 22.979 16.9639 19.079C16.9629 15.176 20.1369 12 24.0379 12C27.9379 12 31.1109 15.176 31.1109 19.079C31.1099 22.979 27.9379 26.152 24.0379 26.152ZM24.0379 13C20.6879 13 17.9629 15.728 17.9629 19.079C17.9629 22.428 20.6879 25.152 24.0369 25.152C27.3859 25.152 30.1099 22.427 30.1099 19.079C30.1099 15.728 27.3859 13 24.0379 13Z" fill="#B9BECB"/>
                </svg>
            `}
        />
    );
};

export { IconUser };
